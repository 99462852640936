<template>
  <v-container fluid class="content">
    <!-- dataGrid -->
    <div
      class="contact_import_download_buttons"
      v-if="showTab === 'projectFiles'"
    >
      <GeneralizedFilter
        :inputFilterObject="inputFilterObject"
        v-on:filterObject="applyFilter($event)"
        v-on:fetchFilters="fetchFileFacets($event)"
      />
    </div>
    <div class="upload_global_suppression">
      <fileUploadGlobalSuppression fileType="Global Suppression" />
    </div>
    <v-row>
      <v-col cols="12" sm="12">
        <div class="header">
          <h1 class="ml-6">Suppression Files</h1>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        class="pb-0 px-8"
        v-if="Object.keys(filterForChips).length && showTab === 'projectFiles'"
      >
        <div>
          <h5
            class="text-overline pl-2"
            style="border-top: 1px solid #ddd; border-bottom: 1px solid #ddd"
          >
            Applied Filter:
            <span
              data-v-41106904=""
              style="
                margin-left: 10px;
                font-family: 'Lato';
                text-transform: none;
                color: rgb(32, 95, 172);
                cursor: pointer;
                letter-spacing: 0px;
                font-size: 13px;
              "
              @click="clearAllFilters()"
              >( Clear all )</span
            >
          </h5>
          <v-chip-group column>
            <span
              v-for="(subProps, key) in filterForChips"
              :key="key"
              style="display: inline"
            >
              <span
                v-if="Array.isArray(subProps.value) && subProps.value.length"
              >
                <v-chip
                  close
                  label
                  @click:close="removeFilter(key, subValue)"
                  v-for="(subValue, index) in subProps.value"
                  :key="index"
                >
                  <span style="padding-right: 5px"> {{ subProps.text }}: </span>
                  <span
                    v-if="
                      subProps.operator !== operatorEqualTo &&
                      subProps.operator !== operatorBetween
                    "
                    style="padding-right: 2px"
                  >
                    {{ subProps.operator }}
                  </span>
                  <span v-if="subValue.length"> {{ subValue }}</span>
                </v-chip>
              </span>
              <span v-else>
                <v-chip close label @click:close="removeFilter(key)">
                  <span style="padding-right: 5px"> {{ subProps.text }}: </span>
                  <span
                    v-if="
                      subProps.operator !== operatorEqualTo &&
                      subProps.operator !== operatorBetween
                    "
                    style="padding-right: 2px"
                  >
                    {{ subProps.operator }}
                  </span>
                  <span v-if="subProps.value.length">
                    {{ subProps.value }}</span
                  >
                </v-chip>
              </span>
            </span>
          </v-chip-group>
        </div>
      </v-col>

      <div class="suppression_list_tabs px-8">
        <div class="tabs">
          <ul>
            <li :class="{ 'is-active': 'projectFiles' == showTab }">
              <a @click="switchTab('projectFiles')">
                <span>Project Files</span>
              </a>
            </li>
            <li :class="{ 'is-active': 'globalFiles' == showTab }">
              <a @click="switchTab('globalFiles')">
                <span>Global Files</span>
              </a>
            </li>
            <li :class="{ 'is-active': 'jobList' == showTab }">
              <a @click="switchTab('jobList')">
                <span>Jobs</span>
              </a>
            </li>
          </ul>

          <hr />

          <div class="tab-details">
            <div v-show="'projectFiles' == showTab">
              <v-col cols="12" sm="12">
                <DataGrid
                  gridId="pmSuppressionFilesPageId"
                  gridRef="pmSuppressionFilesPageRef"
                  gridHeight="53vh"
                  :headers="headers"
                  :itemList="fileList"
                  :loading="loading"
                  :totalCount="totalFileCount"
                  :pageNo="pageNumber"
                  @fetchGrideData="fetchFiles($event)"
                  @viewFile="viewFile($event)"
                  @deleteFile="openDeleteFile($event)"
                />
              </v-col>
            </div>
            <div v-show="'globalFiles' == showTab">
              <v-col cols="12" sm="12">
                <DataGrid
                  gridId="pmGlobalSuppressionFilesPageId"
                  gridRef="pmGlobalSuppressionFilesPageRef"
                  gridHeight="53vh"
                  :headers="headers"
                  :itemList="fileList"
                  :loading="loading"
                  :totalCount="totalFileCount"
                  :pageNo="pageNumber"
                  @fetchGrideData="fetchFiles($event)"
                  @viewFile="viewFile($event)"
                />
              </v-col>
            </div>
            <div v-if="'jobList' === showTab">
              <PROJECTJobStatus isGlobal />
            </div>
          </div>
        </div>
      </div>
    </v-row>
    <v-dialog v-model="projectDialog" max-width="600">
      <v-card>
        <v-toolbar color="#e0e0e0" light>
          <v-toolbar-title
            >{{ selectedFile.fileName }} - Linked Projects ({{
              selectedFile.linkedProjectCount
            }})</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-chip-group active-class="primary--text" column>
            <v-chip
              color="primary"
              outlined
              v-for="project in selectedFile.linkedProjects"
              :key="project"
            >
              {{ project }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeProjectList()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="deleteFileForm" v-model="deleteFileValid" lazy-validation>
      <div class="text-center delete_project_btn">
        <v-dialog v-model="deleteFileDialog" width="500" persistent>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              <div style="display: block; width: 100%">
                <label>Are you sure?</label>
                <v-icon @click="resetDeleteFileDialog()" style="float: right"
                  >mdi-close</v-icon
                >
              </div>
            </v-card-title>

            <v-card-text style="padding-top: 5px" class="pt-4">
              Do you really want to delete this file:
              <span
                style="
                  display: block;
                  color: black;
                  font-weight: bold;
                  font-size: 16px;
                  white-space: pre-wrap;
                "
                >{{ selectedFile.fileName }}</span
              >.<br />
              This action cannot be undone. This will permanently delete the
              file.
            </v-card-text>

            <v-card-text>
              Please type
              <span
                style="
                  color: black;
                  font-weight: bold;
                  font-size: 16px;
                  white-space: pre-wrap;
                "
                >{{ selectedFile.fileName }}</span
              >
              to confirm.

              <v-text-field
                label="Enter File Name"
                v-model="confirmFileName"
                style="padding-top: 10px; padding-bottom: 0px"
                v-on:input="checkFileNameValid()"
                outlined
                dense
                required
                :rules="[(v) => !!v || 'Enter File Name']"
              ></v-text-field>

              <v-btn
                @click="deleteSuppressionFile()"
                class="delete_file_confirmation_btn"
                :loading="deleteLoader"
                :disabled="disableFileDeleteConfirmBtn"
              >
                Delete this file
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import _ from "lodash";
import Api from "../services/Api";
import DataGrid from "./dataGrid.vue";
import GeneralizedFilter from "./GeneralizedFilters.vue";
import fileUploadGlobalSuppression from "./fileUploadGlobalSuppression.vue";
import PROJECTJobStatus from "./PROJECTJobStatus.vue";

export default {
  components: {
    DataGrid,
    GeneralizedFilter,
    fileUploadGlobalSuppression,
    PROJECTJobStatus,
  },
  name: "SuppressionFiles",
  props: {},
  data() {
    return {
      confirmFileName: "",
      disableFileDeleteConfirmBtn: true,
      deleteFileDialog: false,
      deleteFileValid: false,
      projectDialog: false,
      selectedFile: {},
      loading: false,
      fileList: [],
      headers: [],
      limitOfFilterList: 20,
      totalFileCount: 0,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      operatorEqualTo: "=",
      operatorBetween: "between",
      inputFilterObject: {
        fileName: {
          text: "File Name",
          type: "string",
          operators: ["IS"],
          values: [],
        },
        projectName: {
          text: "Project Name",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        client: {
          text: "Client",
          type: "string",
          operators: ["IS"],
          values: [],
        },
        createdBy: {
          text: "Created By",
          type: "array",
          operators: ["IS"],
          values: [],
          search: true,
        },
        createdAt: {
          text: "Created Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
      },
      filterForChips: {},
      filter: {},
      sort: {},
      deleteLoader: false,
      showTab: "projectFiles",
      isGlobalFile: false,
    };
  },
  mounted: async function () {
    await this.fetchSharedFiles();
    await this.fetchFileFacets("client");
  },
  computed: {
    computedHeaders() {
      return [
        {
          value: "fileName",
          text: "File Name",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT35,
          googleSearch: false,
        },
        ...(this.showTab === "globalFiles"
          ? [
              {
                value: "type",
                text: "Type",
                sortable: false,
                divider: true,
                width: "200px",
                type: "string",
                tooltip: this.$constants().COLUMN_LIMIT20,
                googleSearch: false,
              },
            ]
          : [
              {
                value: "client",
                text: "Client",
                sortable: false,
                divider: true,
                width: "200px",
                type: "string",
                tooltip: this.$constants().COLUMN_LIMIT20,
                googleSearch: false,
              },
            ]),
        {
          value: "createdAt",
          text: "Created Date",
          sortable: true,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "createdBy",
          text: "Created By",
          sortable: true,
          divider: true,
          width: "150px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "linkedProjectCount",
          text: "Linked Projects (Counts)",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "fileActions",
          text: "Actions",
          sortable: false,
          divider: true,
          width: "55px",
          type: "fileAction",
          googleSearch: false,
        },
      ];
    },
  },
  watch: {
    showTab: {
      immediate: true,
      handler() {
        this.headers = [...this.computedHeaders];
      },
    },
  },
  methods: {
    async switchTab(payload) {
      this.showTab = payload;
      this.isGlobalFile = payload === "globalFiles";
      if (payload !== "projectFiles") {
        this.clearAllFilters();
        return;
      }
      await this.fetchSharedFiles();
    },
    clearAllFilters: async function () {
      this.filterForChips = {};
      this.filter = {};
      this.sort = {};
      if (this.showTab == "jobList") {
        return;
      }
      await this.fetchSharedFiles();
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    fetchSharedFiles: async function () {
      let self = this;
      self.loading = true;
      try {
        let params = {
          pageNo: self.pageNumber - 1,
          pageSize: self.pageSize,
          filter: JSON.stringify(self.filter),
          sort: JSON.stringify(self.sort),
          ...(this.isGlobalFile && { isGlobalFile: self.isGlobalFile }),
        };
        let fileResult = await Api.getAllSharedFiles(params);

        self.totalFileCount = fileResult.data.totalCount;
        self.fileList = fileResult.data.docs.map((item) => {
          if (item.linkedProjects.length) {
            item.fileActions = "view";
          } else {
            if (!this.isGlobalFile) {
              item.fileActions = "delete";
            }
          }
          return item;
        });
      } catch (e) {
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().FETCH_FILE_ERROR,
        };
        self.setNotification(notification);
        console.error(e);
      }
      self.loading = false;
    },
    removeFilter: async function (filterKey, subValue) {
      this.pageNumber = 1;
      if (!subValue) {
        delete this.filterForChips[filterKey];
        delete this.filter[filterKey];
      } else {
        const filterForChipsValues = _.get(
          this.filterForChips,
          [filterKey, "value"],
          []
        );
        const filterValues = _.get(this.filter, [filterKey, "value"], []);
        if (filterForChipsValues.length <= 1 && filterValues.length <= 1) {
          delete this.filterForChips[filterKey];
          delete this.filter[filterKey];
        } else {
          const filterForChipsValueIndex = _.indexOf(
            filterForChipsValues,
            subValue
          );
          if (filterForChipsValueIndex > -1) {
            this.filterForChips[filterKey]["value"].splice(
              filterForChipsValueIndex,
              1
            );
          }
          const filterValueIndex = _.indexOf(filterValues, subValue);
          if (filterValueIndex > -1) {
            this.filter[filterKey]["value"].splice(filterValueIndex, 1);
          }
        }
      }
      await this.fetchSharedFiles();
    },
    async applyFilter(filterApplied) {
      this.pageNumber = 1;
      const keyOfFilterApplied = Object.keys(filterApplied)[0];
      this.filterForChips[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.modifyFilterForChips(keyOfFilterApplied);
      filterApplied[keyOfFilterApplied] = _.omit(
        filterApplied[keyOfFilterApplied],
        ["text", "itemText", "itemValue", "search", "type"]
      );
      this.filter[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      await this.fetchSharedFiles();
    },
    modifyFilterForChips: function (keyOfFilterApplied) {
      const valueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["value"];
      const operatorOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["operator"];
      const itemTextOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemText"];
      const itemValueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemValue"];
      if (
        operatorOfFilteredForChips === "between" &&
        Array.isArray(valueOfFilteredForChips)
      ) {
        this.filterForChips[keyOfFilterApplied]["value"] =
          this.filterForChips[keyOfFilterApplied]["value"].join(" ~ ");
      }
      if (itemTextOfFilteredForChips && itemValueOfFilteredForChips) {
        let valuesOfFilteredId = _.map(
          this.inputFilterObject[keyOfFilterApplied].values,
          (item) => {
            if (item[itemValueOfFilteredForChips] === valueOfFilteredForChips) {
              return item[itemTextOfFilteredForChips];
            }
          }
        );
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] =
          valuesOfFilteredId[0];
      }
    },
    resetDeleteFileDialog() {
      this.deleteFileDialog = false;
      this.confirmFileName = "";
      this.disableFileDeleteConfirmBtn = true;
      this.$refs.deleteFileForm.resetValidation();
    },
    checkFileNameValid: function () {
      let self = this;
      var formValid = this.$refs.deleteFileForm.validate();
      self.disableFileDeleteConfirmBtn = true;
      if (
        formValid &&
        self.selectedFile.fileName.trim() === self.confirmFileName.trim()
      ) {
        self.disableFileDeleteConfirmBtn = false;
      }
    },
    deleteSuppressionFile: async function () {
      let self = this;
      try {
        self.deleteLoader = true;
        self.disableFileDeleteConfirmBtn = true;
        await Api.deleteSharedFile(this.selectedFile.sharedFileId);
      } catch (e) {
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().FILE_DELETE_ERROR,
        };
        self.setNotification(notification);
      }
      self.disableFileDeleteConfirmBtn = false;
      self.deleteLoader = false;
      self.resetDeleteFileDialog();
      await this.fetchSharedFiles();
    },
    closeProjectList: function () {
      this.projectDialog = false;
      this.selectedFile = {};
    },
    openDeleteFile: function (item) {
      if (this.isGlobalFile) return;
      this.deleteFileDialog = true;
      this.selectedFile = item;
    },
    viewFile: function (item) {
      this.projectDialog = true;
      this.selectedFile = item;
    },
    generateSortPayload(sortBy, sortDesc) {
      var sortPayload = {};
      sortBy.forEach(function (item, index) {
        sortPayload[item] = sortDesc[index] ? "asc" : "desc";
      });
      return sortPayload;
    },
    fetchFiles: async function (options) {
      const { sortBy, sortDesc, page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;
      this.sort = this.generateSortPayload(sortBy, sortDesc);
      await this.fetchSharedFiles();
    },
    fetchFileFacets: async function (field) {
      let self = this;
      switch (field) {
        case "client":
          self.inputFilterObject.client.values = await Api.getSharedFileFacets({
            field: "client",
          })
            .then((response) => {
              return _.compact(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        case "createdBy":
          self.inputFilterObject.createdBy.values =
            await Api.getSharedFileFacets({
              field: "createdBy",
            })
              .then((response) => {
                return _.compact(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
          break;
        default:
          if (field) {
            console.log("Facet field is not defined");
          }
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  padding-left: 55px;
}
.list_item,
.project_list_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.header {
  margin: 15px 0 10px;
}
.header,
.toolbar,
.project_list,
.project_list .list_item {
  float: left;
  display: block;
  /*width: 100%;*/
}

button.btn {
  float: right;
  background: #ffffff;
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  padding: 5px 15px;
  cursor: pointer;
}

.header button {
  margin-right: 15px;
}

.toolbar {
  margin-left: 5px;
}
.toolbar > div {
  display: inline-block;
  margin-right: 15px;
  float: left;
}

.toolbar > div.create_project_wrapper {
  margin-right: 0;
}

.delete_file_confirmation_btn {
  width: 450px;
  font-size: 12px !important;
  border-width: 1px;
  border-style: solid;
  border-color: red;
  color: rgb(228, 31, 31) !important;
  font-weight: bold;
}

.header h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}

.project_list .v-expansion-panels {
  margin-left: 15px;
}
.list_item > div {
  display: block;
}
.list_item .list_item_stats p-2 bd-highlight {
  display: inline-block;
}
.list_item h4,
.list_item h5,
.list_item h6 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.list_item .selection input {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.list_item .selection {
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
}
.list_item .title_section {
  width: 450px;
  margin-right: 30px;
}
.list_item .title_section h4 {
  font-weight: 500;
}
.list_item .title_section h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #075ab1;
}
.stat_item {
  display: inline-block;
  margin: 0 15px;
}
.stat_item h6 {
  font-weight: 400;
  font-size: 13px;
  color: #075ab1;
}

.list_item div.time {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
.switch {
  margin-top: 10px;
  margin-right: 25px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00c537;
}

input:focus + .slider {
  outline: none;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.project_status h6 {
  width: 125px;
  padding: 15px;
  font-size: 18px;
}

.project_list_headers h6 {
  font-family: "Lato";
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase !important;
}
.list_item h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
}
.list_item h6 {
  display: none;
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase !important;
}
.list_item .panel_content h4 {
  text-align: center;
}
.list_item.v-item--active h6 {
  display: block;
}
.list_item h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #25213b;
}
.project_list_headers .v-expansion-panel-header,
.list_item .v-expansion-panel-header,
.list_item .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 5px 15px !important;
}

.panel_content {
  margin-left: -10px;
}
.v-expansion-panels {
  margin: 10px 30px 10px 30px;
}

.field {
  width: 180px;
}

.circle {
  height: 45px;
  width: 45px;
  background-color: #075ab1;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: white !important;
}

.project_list .list_item .v-expansion-panel-header__icon {
  left: 5px;
  position: absolute;
}

.project_link {
  right: 60px;
  top: 15px;
  position: absolute;
}

.project_name_flex {
  width: 350px;
}

.suppression_list_tabs {
  display: block;
  float: left;
  width: 100%;
  /* border-right: 1px solid #aaa; */
}

.tabs li {
  list-style: none;
}
.tabs li a {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
}
.tabs ul {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  padding-left: 0;
}
.tabs li.is-active a {
  border-bottom-color: #3273dc;
  color: #3273dc;
}
.tab-details {
  padding-top: 10px;
}

@media (min-width: 1100px) and (max-width: 1250px) {
  .field.project_name_flex {
    width: 270px;
  }

  .list_item h4 {
    font-size: 15px;
  }

  .field {
    width: 160px;
  }

  .project_list_headers h6 {
    font-size: 14px;
  }
}
.notification {
  position: absolute;
}

@media (min-width: 1250px) and (max-width: 1350px) {
  .field.project_name_flex {
    width: 280px;
  }

  .list_item h4 {
    font-size: 16px;
  }

  .field {
    width: 170px;
  }

  .project_list_headers h6 {
    font-size: 15px;
  }
}

.contact_import_download_buttons {
  position: absolute;
  top: 20px;
  right: 30px;
}

.upload_global_suppression {
  position: absolute;
  top: 20px;
  right: 80px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"contact_import_download_buttons"},[_c('FileUpload',{attrs:{"fileType":"Import","projectId":_vm.projectId,"projectName":_vm.projectName,"isButtonDisabled":_vm.isButtonDisabled,"importType":"Contact"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 white--text",attrs:{"color":"#8B83BA","fab":"","small":"","loading":_vm.isDownloading,"disabled":_vm.isDownloading},on:{"click":function($event){return _vm.contactDownload(_vm.selectedStage)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("download")])],1)]}}])},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 white--text",attrs:{"color":"#8B83BA","loading":_vm.loading,"disabled":_vm.loading,"fab":"","small":""},on:{"click":function($event){return _vm.pageRefresh()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('GeneralizedFilter',{attrs:{"inputFilterObject":_vm.inputFilterObject},on:{"filterObject":function($event){return _vm.applyFilter($event)},"fetchFilters":function($event){return _vm.fetchContactFacets($event)}}}),_c('AssignTasks',{ref:"AssignTasks",attrs:{"pageName":"contact","projectId":_vm.projectId,"filter":_vm.filter,"maxBlockSize":_vm.contactsTotalCount,"isButtonDisabled":_vm.isButtonDisabled}})],1),_c('v-container',{staticClass:"contacts",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"11"}},[_c('v-slide-group',{staticClass:"counter_stats pr-6",attrs:{"active-class":"activeDisposition","show-arrows":""},model:{value:(_vm.mainDisposition),callback:function ($$v) {_vm.mainDisposition=$$v},expression:"mainDisposition"}},_vm._l((_vm.researchStatusList),function(researchStatus){return _c('v-slide-item',{key:researchStatus.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ml-2 counter",attrs:{"height":"80","width":"150","color":active ? '#205fac' : 'FFFFFF'},on:{"click":[toggle,function($event){return _vm.assignStyletoSplitResearchStatus(researchStatus.status)}]}},[_c('h4',{staticClass:"counter_value pt-2"},[_vm._v(" "+_vm._s(_vm._f("humanizeNumber")(researchStatus.count))+" ")]),(_vm.researchStatusMapping[researchStatus.status])?_c('h4',{staticClass:"counter_title"},[_vm._v(" "+_vm._s(_vm.researchStatusMapping[researchStatus.status])+" ")]):(researchStatus.status)?_c('h4',{staticClass:"counter_title"},[_vm._v(" "+_vm._s(researchStatus.status)+" ")]):_c('h4',{staticClass:"counter_title"},[_vm._v("Pending")])])]}}],null,true)})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}})],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"11"}},[_c('v-slide-group',{staticClass:"counter_stats pr-6",style:(_vm.styleSplitResearchStatus),attrs:{"active-class":"activeDisposition"}},_vm._l((_vm.splittedResearchStatus),function(value,name){return _c('v-slide-item',{key:value.id,attrs:{"show-arrows":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ml-2 mb-2 counter",attrs:{"height":"80","width":"150","color":active ? '#205fac' : 'FFFFFF'},on:{"click":[toggle,function($event){return _vm.filterListByResearchStatus(name)}]}},[_c('h4',{staticClass:"counter_value pt-2"},[_vm._v(" "+_vm._s(_vm._f("humanizeNumber")(value))+" ")]),_c('h4',{staticClass:"counter_title"},[_vm._v(_vm._s(name))])])]}}],null,true)})}),1)],1)],1),(Object.keys(_vm.filterForChips).length)?_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"11"}},[_c('v-card',{staticClass:"px-3",attrs:{"elevation":"1"}},[_c('h5',{staticClass:"text-overline pl-2",staticStyle:{"border-bottom":"1px solid #ddd"}},[_vm._v(" Applied Filter: "),_c('span',{staticStyle:{"margin-left":"10px","font-family":"'Lato'","text-transform":"none","color":"rgb(32, 95, 172)","cursor":"pointer","letter-spacing":"0px","font-size":"13px"},attrs:{"data-v-41106904":""},on:{"click":function($event){return _vm.clearAllFilters()}}},[_vm._v("( Clear all )")])]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.filterForChips),function(subProps,key){return _c('span',{key:key,staticStyle:{"display":"inline"}},[(Array.isArray(subProps.value) && subProps.value.length)?_c('span',_vm._l((subProps.value),function(subValue,index){return _c('v-chip',{key:index,attrs:{"close":"","label":""},on:{"click:close":function($event){return _vm.removeFilter(key, subValue)}}},[_c('span',{staticStyle:{"padding-right":"5px"}},[_vm._v(" "+_vm._s(subProps.text)+": ")]),(
                      subProps.operator !== _vm.operatorEqualTo &&
                      subProps.operator !== _vm.operatorBetween
                    )?_c('span',{staticStyle:{"padding-right":"2px"}},[_vm._v(" "+_vm._s(subProps.operator)+" ")]):_vm._e(),(subValue.length)?_c('span',[_vm._v(" "+_vm._s(subValue))]):_vm._e()])}),1):_c('span',[_c('v-chip',{attrs:{"close":"","label":""},on:{"click:close":function($event){return _vm.removeFilter(key)}}},[_c('span',{staticStyle:{"padding-right":"5px"}},[_vm._v(" "+_vm._s(subProps.text)+": ")]),(
                      subProps.operator !== _vm.operatorEqualTo &&
                      subProps.operator !== _vm.operatorBetween
                    )?_c('span',{staticStyle:{"padding-right":"2px"}},[_vm._v(" "+_vm._s(subProps.operator)+" ")]):_vm._e(),(subProps.value.length)?_c('span',[_vm._v(" "+_vm._s(subProps.value))]):_vm._e()])],1)])}),0)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"11"}},[_c('DataGrid',{attrs:{"gridId":"pmContactPageId","gridRef":"pmContactPageRef","gridHeight":"53vh","headers":_vm.headers,"itemList":_vm.contactList,"loading":_vm.loading,"totalCount":_vm.contactsTotalCount,"pageNo":_vm.pageNumber,"allowMultiSort":true},on:{"fetchGrideData":function($event){return _vm.fetchTaskData($event)}}})],1)],1),_c('v-row',{staticClass:"container-fluid"},[_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('div',{staticClass:"counter_stage",staticStyle:{"margin-top":"120px"}},[_c('v-icon',{staticClass:"arrow_forward_ios"},[_vm._v("arrow_forward_ios")]),_c('v-icon',{staticClass:"arrow_back_ios"},[_vm._v("arrow_back_ios")]),_c('div',{staticClass:"counter",class:{ active: 'Total' === _vm.selectedStage },on:{"click":function($event){return _vm.applyStageAsFilter('Total')}}},[_c('h4',{staticClass:"counter_value"},[_vm._v(" "+_vm._s(_vm._f("humanizeNumber")(_vm.stagesTotalCount))+" ")]),_c('h4',{staticClass:"counter_title"},[_vm._v("Total")])]),_vm._l((_vm.stages),function(stage){return _c('div',{key:stage.id,staticClass:"counter",class:{ active: stage.stage === _vm.selectedStage },on:{"click":function($event){return _vm.applyStageAsFilter(stage.stage)}}},[_c('h4',{staticClass:"counter_value"},[_vm._v(_vm._s(_vm._f("humanizeNumber")(stage.count)))]),(stage.stage)?_c('h4',{staticClass:"counter_title"},[_vm._v(" "+_vm._s(stage.stage)+" ")]):_c('h4',{staticClass:"counter_title"},[_vm._v("Pending")])])})],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from "axios";

const api = axios.create({
  baseURL: window.location.protocol + "//" + window.location.host + "/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      // alert("Your LogIn Token Is Expired Please LogIn Again");
      let logoutDomain =
        location.protocol + "//" + location.host + "/auth/login.html";
      window.auth0Client.logout({
        returnTo: logoutDomain,
        clientId: process.env.VUE_APP_OAUTHCLIENTID,
      });
      localStorage.clear();
    } else {
      return Promise.reject(error);
    }
  }
);

const Api = {
  getProjectList: async function (params) {
    return api.get("/project", {
      params: params,
    });
  },
  createProject: async function (project) {
    return api.post("/project", {
      project: project,
    });
  },
  getClients: async function (params) {
    return api.get("/client", {
      params: params,
    });
  },
  getProjectTypes: async function () {
    return api.get("/projects/types");
  },
  createClient: async function (client) {
    return api.post("/client", {
      client: client,
    });
  },
  editClient: async function (clientId, client) {
    return api.put("/client/" + clientId, {
      client: client,
    });
  },
  editProjectSetting: async function (projectId, projectSetting) {
    return api.put("/project/" + projectId + "/setting", {
      projectSetting: projectSetting,
    });
  },
  editProjectIntegrations: async function (
    projectId,
    projectIntegrationPayload
  ) {
    return api.post(
      "/project/" + projectId + "/masterImport/account",
      projectIntegrationPayload
    );
  },
  editProjectIntegrationsContact: async function (
    projectId,
    projectIntegrationPayload
  ) {
    return api.post(
      "/project/" + projectId + "/masterImport/contact",
      projectIntegrationPayload
    );
  },
  importAndFetchMasterContacts: async function (
    projectId,
    projectIntegrationPayload
  ) {
    return api.post(
      "/project/" + projectId + "/masterImport/contact",
      projectIntegrationPayload
    );
  },
  addContactToGoldmine: async function (
    projectId,
    contactId,
    flag,
    accountId,
    clientId
  ) {
    return api.post(
      "/project/" + projectId + "/masterContact/contacts/" + contactId,
      {
        flag,
        accountId,
        clientId,
      }
    );
  },
  refreshMasterContactsStatus: async function (projectId, accountId) {
    return api.get("/project/" + projectId + "/jobs", {
      params: {
        activeMasterContactImport: true,
        accountId,
      },
    });
  },
  fetchMasterContacts: async function (
    projectId,
    accountDomain,
    payload,
    pageNo,
    pageSize
  ) {
    return api.get("/project/" + projectId + "/masterContact", {
      params: {
        accountDomain,
        filter: payload,
        pageNo,
        pageSize,
      },
    });
  },
  getProject: async function (projectId) {
    return api.get("/project/" + projectId);
  },
  deleteProject: async function (projectId, params) {
    return api.delete("/project/" + projectId, {
      params: params,
    });
  },
  getUser: async function (params) {
    return api.get("/userSearch", {
      params: params,
    });
  },
  autoCompleteInterFace: async function (autocompleteField, params) {
    return api.get(autocompleteField, {
      params: params,
    });
  },
  getFiles: async function (projectId) {
    return api.get("/files", {
      params: {
        projectId: projectId,
      },
    });
  },
  deleteSpec: async function (projectId, specId) {
    return api.delete("project/" + projectId + "/specs/" + specId);
  },
  downloadFile: async function (fileId) {
    return api.get("files/" + fileId + "?shouldGenerateDownloadUrl=true");
  },
  deleteFile: async function (fileId) {
    return api.delete("files/" + fileId);
  },
  editSettingCounter: async function (projectId, projectSetting) {
    return api.put("/projects/" + projectId + "/editSettings", {
      projectSetting: projectSetting,
    });
  },
  editSpec: async function (projectId, projectSpecId, projectSpec) {
    return api.put("project/" + projectId + "/specs/" + projectSpecId, {
      projectSpec: projectSpec,
    });
  },
  createSpec: async function (projectId, projectSpec) {
    return api.post("project/" + projectId + "/specs", {
      projectSpec: projectSpec,
    });
  },
  getSettings: async function (projectId) {
    return api.get("/project/" + projectId + "/setting");
  },
  getSpecs: async function (projectId) {
    return api.get("/project/" + projectId + "/specs/");
  },
  getContacts: async function (projectId, params) {
    return api.get("project/" + projectId + "/contacts", {
      params: params,
    });
  },
  getContactFacets: async function (projectId, params) {
    return api.get("project/" + projectId + "/contacts/facets", {
      params: params,
    });
  },
  getContactsOfAccount: async function (projectId, accountId, params) {
    return api.get(
      "projects/" + projectId + "/accounts/" + accountId + "/contacts",
      {
        params: params,
      }
    );
  },
  getContactById: async function (projectId, accountId, contactId) {
    return api.get(
      "projects/" +
        projectId +
        "/accounts/" +
        accountId +
        "/contacts/" +
        contactId
    );
  },
  //Contact
  getStats: async function (projectId, params) {
    return api.get("projects/" + projectId + "/contacts/stats", {
      params: params,
    });
  },
  //Account
  getAccountStats: async function (projectId, params) {
    return api.get("projects/" + projectId + "/accounts/stats", {
      params: params,
    });
  },
  getAccountContactCount: async function (projectId, params) {
    return api.get("projects/" + projectId + "/count", {
      params: params,
    });
  },
  // Task
  getTaskStatsOfAProject: async function (projectId, params) {
    return api.get("projects/" + projectId + "/tasks/stats", {
      params: params,
    });
  },
  downloadContact: async function (projectId, params) {
    return api.get("project/" + projectId + "/contacts", {
      params: params,
    });
  },
  getAccounts: async function (projectId, params) {
    return api.get("project/" + projectId + "/accounts", {
      params: params,
    });
  },
  checkAccountsContacts: async function (projectId, params) {
    return api.get("project/" + projectId + "/checkAccountsContacts", {
      params: params,
    });
  },
  getAccountDispositions: async function (projectId) {
    return api.get("project/" + projectId + "/accounts/dispositions");
  },
  getAccountById: async function (projectId, accountId) {
    return api.get("project/" + projectId + "/accounts/" + accountId);
  },
  downloadAccount: async function (projectId, params) {
    return api.get("project/" + projectId + "/accounts", {
      params: params,
    });
  },
  uploadFile: async function (formData, config) {
    return api.post("/files", formData, config);
  },
  checkSuppressionFileExistance: async function (
    fileName,
    clientId,
    projectId
  ) {
    return api.post(
      "/clients/" +
        clientId +
        "/projects/" +
        projectId +
        "/sharedFiles?checkSuppressionFileExistance=true",
      {
        fileName,
      }
    );
  },
  getGlobalJobList: async function (params) {
    return api.get("/jobs", {
      params: params,
    });
  },
  getJobList: async function (projectId, params) {
    return api.get("project/" + projectId + "/jobs", {
      params: params,
    });
  },
  getActiveJobLabel: async function (projectId) {
    return api.get("project/" + projectId + "/jobs", {
      params: {
        activeTaskAllocationLabel: true,
      },
    });
  },
  getJobDetails: async function (projectId, jobId) {
    return api.get("project/" + projectId + "/jobs/" + jobId);
  },
  setJobStatus: async function (projectId, jobId, message) {
    return api.put("project/" + projectId + "/jobs/" + jobId, {
      status: message,
    });
  },
  downloadErrorFile: async function (projectId, jobId, params) {
    return api.get("project/" + projectId + "/jobs/" + jobId + "/jobErrors", {
      params: params,
    });
  },
  downloadGlobalErrorFile: async function (jobId, params) {
    return api.get("jobs/" + jobId + "/jobErrors", {
      params: params,
    });
  },
  downloadJobFile: async function (projectId, jobId, downloadWithId) {
    if (downloadWithId) {
      return api.get(
        "project/" +
          projectId +
          "/jobs/" +
          jobId +
          "/getSignedURL?originalFileWithIds=true"
      );
    }
    return api.get("project/" + projectId + "/jobs/" + jobId + "/getSignedURL");
  },
  downloadGlobalJobFile: async function (jobId, downloadWithId) {
    const url = `jobs/${jobId}/getSignedURL${
      downloadWithId ? "?originalFileWithIds=true" : ""
    }`;
    return api.get(url);
  },
  getAgents: async function (params) {
    return api.get("/users", {
      params: params,
    });
  },
  getTaskTypes: async function () {
    return api.get("projects/taskTypes");
  },
  getTaskTypeForProject: async function (projectId) {
    return api.get("project/" + projectId + "/taskType");
  },
  getAllTask: async function (projectId, params) {
    return api.get("/projects/" + projectId + "/tasks", {
      params: params,
    });
  },
  getTaskFacets: async function (projectId, params) {
    return api.get("/projects/" + projectId + "/tasks/facets", {
      params: params,
    });
  },
  downloadAllTask: async function (projectId, params) {
    return api.get("/projects/" + projectId + "/tasks", {
      params: params,
    });
  },
  getTaskById: async function (taskId, projectId) {
    return api.get("/projects/" + projectId + "/tasks/" + taskId);
  },
  getTaskStats: async function (params) {
    return api.get("/taskStats", {
      params: params,
    });
  },
  getTaskStatsForAgent: async function (projectId) {
    return api.get("/projects/" + projectId + "/tasks", {
      params: {
        countOnly: true,
        filter: [],
        sort: null,
      },
    });
  },
  assignTask: async function (projectId, payload, params) {
    return api.post("/projects/" + projectId + "/tasks", payload, {
      params: params,
    });
  },
  contactCheck: async function (projectId, contact) {
    return api.post(
      "projects/" +
        projectId +
        "/contacts/check?checkSuppression=true&checkDuplicate=true",
      {
        contact: contact,
      }
    );
  },
  checkReusable: async function (clientId, payload) {
    return api.post("clients/" + clientId + "/contacts/checkReuse", payload);
  },
  saveContact: async function (
    projectId,
    accountId,
    contact,
    disposeContact,
    taskLink,
    contactExpiry,
    clientId
  ) {
    return api.post(
      "projects/" + projectId + "/accounts/" + accountId + "/contacts",
      {
        contact: contact,
        disposeContact: disposeContact,
        taskLink: taskLink,
        contactExpiry: contactExpiry,
        clientId: clientId,
      }
    );
  },
  disposeAccount: async function (projectId, account, taskLink) {
    return api.post("project/" + projectId + "/account/dispose", {
      account: account,
      taskLink: taskLink,
    });
  },
  saveAccount: async function (projectId, accountId, account) {
    return api.put("project/" + projectId + "/accounts/" + accountId, account);
  },
  getJobTitle: async function (jobTitle) {
    return api.get("/jobTitle", {
      params: {
        jobTitle: jobTitle,
      },
    });
  },
  getExcludedJobTitles: async function (projectId) {
    return api.get("projects/" + projectId + "/excludedJobTitle");
  },
  getJobLevel: async function () {
    return api.get("/jobLevel");
  },
  getJobFunction: async function (departments) {
    return api.get("/jobFunction", {
      params: {
        departments: departments,
      },
    });
  },
  getJobDepartment: async function () {
    return api.get("/jobDepartment");
  },
  getAllDispostions: async function () {
    return api.get("/dispositions");
  },
  getAllIndustry: async function () {
    return api.get("/industry");
  },
  getAllSubIndustry: async function (industrys) {
    return api.get("/subIndustry", {
      params: {
        industrys: industrys,
      },
    });
  },
  getAllSegmentTechnology: async function () {
    return api.get("/segmentTechnology");
  },
  checkZeroBounce: async function (taskId, emailId) {
    return api.get("/integrations/zb", {
      params: {
        taskId: taskId,
        emailId: emailId,
      },
    });
  },
  addDynamicEmailPattern: async function (
    email,
    firstName,
    middleName,
    lastName,
    verifiedData
  ) {
    return api.post("/dynamicEmailPattern", {
      email: email,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      verifiedData: verifiedData || {},
    });
  },
  getEmailPatterns: async function (firstName, middleName, lastName, domain) {
    return api.get("/emailPattern", {
      params: {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        domain: domain,
      },
    });
  },
  getTasksLiveCountsForAgent: async function (params) {
    return api.get("/tasks/tasksLiveCounts", {
      params: params,
    });
  },
  getAgentPreview: async function (params) {
    return api.get("/agent/preview", {
      params: params,
    });
  },
  getTemplates: async function (projectId) {
    return api.get("/project/" + projectId + "/template");
  },
  getTemplateNames: async function (params) {
    return api.get("/projects/templates", {
      params: params,
    });
  },
  getTemplateById: async function (templateId) {
    return api.get("/projects/templates/" + templateId);
  },
  getGridConfigOfTemplate: async function (templateId) {
    return api.get("/projects/templates/" + templateId + "/gridConfigs");
  },
  saveTemplate: async function (templateId, name, config) {
    return api.put("/projects/templates/" + templateId, {
      name,
      config,
    });
  },
  createTemplate: async function (name, config) {
    return api.post("/projects/templates", {
      name,
      config,
    });
  },
  unlinkSharedFile: async function (clientId, projectId, shareFileId) {
    return api.delete(
      "/clients/" +
        clientId +
        "/projects/" +
        projectId +
        "/sharedFiles/" +
        shareFileId
    );
  },
  getSharedFiles: async function (clientId, projectId, params) {
    return api.get(
      "/clients/" + clientId + "/projects/" + projectId + "/sharedFiles",
      {
        params: params,
      }
    );
  },
  getAllSharedFiles: async function (params) {
    params.onlySharedFiles = true;
    return api.get("/files", {
      params: params,
    });
  },
  getSharedFileFacets: async function (params) {
    params.onlySharedFiles = true;
    return api.get("/files/facets", {
      params: params,
    });
  },
  deleteSharedFile: async function (fileId) {
    return api.delete("/files/" + fileId);
  },
  taskAssignTemp: async function (projectId, jobId, params) {
    return api.get(
      "/projects/" + projectId + "/jobs/" + jobId + "/taskAllocationTemps",
      {
        params: params,
      }
    );
  },
  updateAgent: async function (projectId, jobId, taskId, payload) {
    return api.put(
      "/projects/" +
        projectId +
        "/jobs/" +
        jobId +
        "/taskAllocationTemps/" +
        taskId,
      payload
    );
  },
  deleteAllocation: async function (projectId, jobId, taskId) {
    return api.delete(
      "/projects/" +
        projectId +
        "/jobs/" +
        jobId +
        "/taskAllocationTemps/" +
        taskId
    );
  },
  linkSharedFiles: async function (clientId, projectId, selectedFiles) {
    return api.post(
      "/clients/" + clientId + "/projects/" + projectId + "/sharedFiles",
      {
        fileIds: selectedFiles,
      }
    );
  },
  getTaskAllocationStrategies: async function () {
    return api.get("/taskAllocationStrategies/");
  },
  checkAccount: async function (projectId, account, params) {
    return api.post("/projects/" + projectId + "/account/check", account, {
      params: params,
    });
  },
  getSIC: async function (params) {
    return api.get("sicCode?param=" + params);
  },
  getNAICS: async function (params) {
    return api.get("naicsCode?param=" + params);
  },
};

export default Api;

<template>
  <div class="d-inline-block">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <!-- Render passed child button/component -->
        <template v-if="$scopedSlots.default">
          <!-- <div v-bind="[attrs]" v-on="on"> -->
          <slot :on="on" :attrs="attrs" :triggerClick="openDialog"></slot>
          <!-- </div> -->
        </template>
        <!-- Render default button -->
        <template v-else>
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#8B83BA"
            depressed
            fab
            small
            dark
            @click="openDialog()"
            :class="{ disabled_file_upload_button: isButtonDisabled }"
            ><v-icon>upload</v-icon></v-btn
          >
        </template>
      </template>
      <span>Upload File</span>
    </v-tooltip>
    <v-dialog v-model="fileUploadDialog" fullscreen hide-overlay>
      <v-card>
        <div class="content">
          <v-toolbar dark color="#7c7c7c">
            <v-toolbar-title>File Upload</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="fileUploadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <!-- stepper -->
          <v-stepper v-model="stepperStepNo">
            <!--   -->
            <v-stepper-header>
              <v-stepper-step :complete="stepperStepNo > 1" step="1">
                Choose Data
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="stepperStepNo > 2" step="2">
                Edit Mapping
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3"> Start Import </v-stepper-step>
            </v-stepper-header>

            <!-- step-1 -->
            <v-stepper-content step="1">
              <div class="fileImportForm">
                <v-row
                  class="fileDetailField pt-7"
                  style="position: absolute; left: 60%"
                  v-if="
                    selectedImportType &&
                    file &&
                    (!this.parsingError.code ||
                      this.parsingError.code === 'Headers')
                  "
                >
                  <standard-headers
                    :fileType="fileType"
                    :importType="selectedImportType"
                    :filteredMappingFields="filteredMappingFields"
                  />
                </v-row>

                <v-row class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label"> Project Name: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <label class="fileDetailValue"> {{ projectName }} </label>
                  </v-col>
                </v-row>
                <v-row class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label"> File Type: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <label class="fileDetailValue"> {{ fileType }} </label>
                  </v-col>
                </v-row>

                <v-row class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label"> Import Type: </label>
                  </v-col>

                  <v-col
                    v-if="importType == 'Task'"
                    class="d-flex"
                    cols="12"
                    sm="9"
                  >
                    <v-select
                      class="fileDetailValue"
                      v-on:change="filterMappingFields()"
                      v-model="selectedImportType"
                      :items="taskImportType"
                      label="Select Import Type"
                      return-object
                      single-line
                      solo
                      clearable
                    ></v-select>
                  </v-col>

                  <v-col v-else class="d-flex" cols="12" sm="9">
                    <v-select
                      class="fileDetailValue"
                      v-on:change="filterMappingFields()"
                      v-model="selectedImportType"
                      :items="importTypes"
                      label="Select Import Type"
                      return-object
                      single-line
                      solo
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row v-show="importType == 'Task'" class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label"> Task Type: </label>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="9">
                    <v-select
                      class="fileDetailValue"
                      v-on:change="mappingObjectTypeField(selectedTaskType)"
                      v-model="selectedTaskType"
                      :items="taskTypes"
                      item-value="id"
                      item-text="type"
                      label="Select Task Type"
                      return-object
                      single-line
                      solo
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row v-show="importType == 'Task'" class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label"> Object Type: </label>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="9">
                    <v-select
                      class="fileDetailValue"
                      v-model="selectedObjectType"
                      :items="objectTypes"
                      label="Select Object Type"
                      return-object
                      single-line
                      solo
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label"> Select File: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <v-spacer>
                      <v-file-input
                        class="fileDetailValue"
                        :prepend-icon="importFileIcon"
                        :color="importFileIconColor"
                        v-model="file"
                        label="Select File"
                        counter
                        accept=".csv"
                        show-size
                        v-on:change="selectFile"
                        truncate-length="27"
                        solo
                      ></v-file-input>
                    </v-spacer>
                  </v-col>
                </v-row>
                <v-row class="mt-6" v-if="parsingError.code">
                  <div class="parsingError warning">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-uppercase"
                          >Error in Parsing File</v-list-item-title
                        >
                        <v-list-item-title class="mt-2"
                          ><b>Reason</b>:
                          {{ parsingError.message }}</v-list-item-title
                        >
                        <v-list-item-title class="mt-2"
                          ><b>Line Number</b>:
                          {{ parsingError.row + 2 }}</v-list-item-title
                        >
                        <v-list-item-title class="mt-2"
                          ><b>Type of Error</b>: {{ parsingError.type }} -
                          {{ parsingError.code }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-row>
              </div>
              <v-alert
                outlined
                type="error"
                v-if="suppressionFileExistanceMsg"
                style="width: 60%; margin: 50px auto"
              >
                {{ suppressionFileExistanceMsg }}
              </v-alert>
              <v-btn
                class="mt-10"
                color="#8B83BA"
                dark
                depressed
                @click="initMapping()"
                :disabled="disabledJumpMappingBtn"
              >
                Next
              </v-btn>
            </v-stepper-content>

            <!-- step-2 -->
            <v-stepper-content step="2">
              <div class="mappingForm">
                <v-row no-gutters>
                  <template v-for="(fileHeader, index) in fileHeaders">
                    <v-row class="mappingField" :key="index">
                      <v-col class="d-flex mappingFieldLabel" cols="12" sm="3">
                        <label class="label"> {{ fileHeader }}</label>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="4">
                        <v-autocomplete
                          clearable
                          solo
                          class="mappingDetail"
                          v-on:change="
                            mappingColumn(fileHeader, mapping[fileHeader])
                          "
                          v-model="mapping[fileHeader]"
                          :items="filteredMappingFields"
                          item-text="label"
                          item-value="id"
                          persistent-hint
                          return-object
                          single-line
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </template>
                  <div class="parsingError warning mt-6" v-if="mappingError">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <b>Mandatory Fields</b>: {{ mappingError }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-row>
              </div>

              <v-btn class="mr-2 mt-10" @click="stepperStepNo = 1">
                Back
              </v-btn>

              <v-btn
                color="#8B83BA"
                dark
                depressed
                class="mt-10"
                @click="checkMappingAndProceed()"
              >
                Next
              </v-btn>
            </v-stepper-content>

            <!-- step-3 -->
            <v-stepper-content step="3"
              ><v-list-item class="start_import_wrapper">
                <v-list-item-content>
                  <h6 class="mb-4">Project Name</h6>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ projectName }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <h6 class="mb-4">Type of Import</h6>
                  <v-list-item-title class="text-h5 mb-1 text-capitalize">
                    {{ selectedImportType }} {{ fileType }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <h6 class="mb-4">File Name</h6>
                  <v-list-item-title class="text-h5 mb-1">{{
                    selectedFileName
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <h6 class="mb-4">No. of Rows</h6>
                  <v-list-item-title class="text-h5 mb-1">{{
                    countFileRow
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-btn class="mr-2 mt-10" @click="stepperStepNo = 2">
                Back
              </v-btn>
              <v-btn
                class="mt-10"
                color="#8B83BA"
                dark
                depressed
                @click="uploadFile()"
                :disabled="disabledFileUploadBtn"
              >
                File Upload
              </v-btn>

              <v-progress-circular
                class="ml-4 mt-10"
                v-show="showFileUploadProgress"
                :size="30"
                :width="3"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-stepper-content>
          </v-stepper>
          <v-stepper v-model="stepperStepNo"> </v-stepper>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="supportFileUploadDialog" fullscreen hide-overlay>
      <v-card>
        <div class="content">
          <v-toolbar dark color="#7c7c7c">
            <v-toolbar-title>Support File Upload</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="supportFileUploadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div class="supportFileUpload">
            <div class="fileImportForm">
              <v-row class="fileDetailField">
                <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                  <label class="label"> Project Name : </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="9">
                  <label class="fileDetailValue"> {{ projectName }} </label>
                </v-col>
              </v-row>
              <v-row class="fileDetailField">
                <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                  <label class="label"> File Type : </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="9">
                  <label class="fileDetailValue"> {{ fileType }} </label>
                </v-col>
              </v-row>

              <v-row class="fileDetailField">
                <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                  <label class="label"> Select File : </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="9">
                  <v-spacer>
                    <v-file-input
                      class="fileDetailValue"
                      :prepend-icon="importFileIcon"
                      :color="importFileIconColor"
                      v-model="file"
                      label="Select File"
                      accept=".csv,.pdf,.doc,.docx,.odf,.xsl,.xlsx,.png,.jpg,.jpeg"
                      counter
                      show-size
                      v-on:change="selectSupportFile"
                      truncate-length="27"
                      solo
                    ></v-file-input>
                  </v-spacer>
                </v-col>
              </v-row>
            </div>
            <div class="mt-6" v-if="parsingError.code">
              <div class="parsingError warning">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase">
                      Error in Parsing File
                    </v-list-item-title>
                    <v-list-item-title class="mt-2">
                      <b>Reason</b>: {{ parsingError.message }}
                    </v-list-item-title>
                    <v-list-item-title class="mt-2">
                      <b>Line Number</b>: {{ parsingError.row + 2 }}
                    </v-list-item-title>
                    <v-list-item-title class="mt-2">
                      <b>Type of Error</b>: {{ parsingError.type }} -
                      {{ parsingError.code }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>

            <v-btn
              class="mt-10"
              color="#8B83BA"
              dark
              depressed
              @click="uploadFile()"
              :disabled="disabledSupportFileUploadBtn"
            >
              File Upload
            </v-btn>
            <!-- <v-btn @click="stepperStepNo = 2"> Back </v-btn> -->
            <v-progress-circular
              class="ml-4 mt-10"
              v-show="showFileUploadProgress"
              :size="30"
              :width="3"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import Api from "../services/Api";
import standardHeaders from "./standardHeaders.vue";
var mappingFields = require("./mappingFields");

export default {
  name: "FileUpload",
  props: {
    fileType: String,
    projectId: String,
    projectName: String,
    importType: String,
    isButtonDisabled: Boolean,
  },
  components: {
    standardHeaders,
  },
  data() {
    return this.initialState();
  },
  computed: {
    clientId() {
      return this.$store.state.projectClientId;
    },
  },
  mounted: function () {
    this.pageLoad();
  },
  methods: {
    getTemplate: async function () {
      let { data: template } = await Api.getTemplates(this.projectId);
      this.extractKeysAndLabelsFromTemplate(template);
    },
    extractKeysAndLabelsFromTemplate: function (template) {
      let contact = Object.fromEntries(
        _.flattenDepth(
          _.get(template, "config.forms.AgentContactWorkspace.config", []).map(
            (contactConfig) =>
              _.get(contactConfig, "fields", []).map((field) => [
                field.id,
                field.label,
              ])
          ),
          1
        )
      );

      _.set(
        this.templateMapping,
        "contact",
        JSON.parse(JSON.stringify(contact).replaceAll("contact.", ""))
      );

      let account = Object.fromEntries(
        _.flattenDepth(
          _.get(template, "config.forms.AgentAccountWorkspace.config", []).map(
            (accountConfig) =>
              _.get(accountConfig, "fields", []).map((field) => [
                field.id,
                field.label,
              ])
          ),
          1
        )
      );

      _.set(
        this.templateMapping,
        "account",
        JSON.parse(JSON.stringify(account).replaceAll("account.", ""))
      );
    },
    pageLoad: function () {
      Object.assign(this.$data, this.initialState());
      this.$forceUpdate();
    },
    openDialog: async function () {
      this.pageLoad();
      try {
        await this.getTemplate();
      } catch (error) {
        console.log(`Could Not Get Template Mapping ${error.message}`);
      }
      if (this.fileType == "Supporting Document") {
        this.supportFileUploadDialog = true;
      } else if (this.importType == "Task" && this.fileType == "Import") {
        this.fetchTaskTypes();
        this.fetchTaskTypeForProject();
        this.fileUploadDialog = true;
      } else if (
        this.importType == "Account Contact" &&
        this.fileType == "Import"
      ) {
        this.importTypes.push("Account Contact");
        this.fileUploadDialog = true;
      } else {
        this.fileUploadDialog = true;
      }

      if (this.importType) {
        this.filterMappingFields();
      }
    },
    initialState: function () {
      return {
        fileUploadDialog: false,
        supportFileUploadDialog: false,
        taskImportType: ["Task"],
        countFileRow: 0,
        totalErrorFileParse: 0,
        progress: 0,
        file: null,
        fileParseComplete: false,
        disabledJumpMappingBtn: true,
        disabledFileUploadBtn: false,
        showFileUploadProgress: false,
        columnIsDuplicate: false,
        isAllowedFileType: true,
        disabledSupportFileUploadBtn: true,
        errorTimeOut: 5000,
        stepperStepNo: 1,
        selectedImportType: this.importType || "",
        selectedTaskType: null,
        selectedFileName: "",
        importTypes: ["Account", "Contact"],
        importFileIcon: "attach_file",
        importFileIconColor: "primary",
        filteredMappingFields: [],
        mapping: {},
        mappingError: null,
        operationName: "",
        taskTypes: [],
        selectedObjectType: null,
        objectTypes: ["Account", "Contact"],
        mappingObjectType: {
          "01": "Account",
          "02": "Account",
          "03": "Account",
          "04": "Contact",
          "05": "Account",
          "06": "Account",
          "07": "Account",
          "08": "Account",
        },
        mappingScenarios: [
          {
            selectedImportType: "Account Contact",
            fileType: "Import",
            requiredFields: ["account.id"],
          },
          {
            selectedImportType: "Account",
            fileType: "Import",
            requiredFields: ["account.id", "account.label"],
          },
        ],
        parsingError: {},
        templateMapping: {},
        fileHeaders: [],
        suppressionFileExistanceMsg: "",
      };
    },
    filterMappingFields: function () {
      this.enableJumpMappingBtn();
      var processMappingFields = JSON.parse(JSON.stringify(mappingFields));
      const accountTemplateMapping = _.get(this.templateMapping, "account", {});
      const contactTemplateMapping = _.get(this.templateMapping, "contact", {});
      this.filteredMappingFields = processMappingFields.filter(
        (mappingField) => {
          if (
            this.selectedImportType === "Account" &&
            (this.fileType === "Inclusion" || this.fileType === "Import") &&
            mappingField["type"].includes("account")
          ) {
            mappingField.label = `Account.${_.get(
              accountTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            mappingField.id = `account.${mappingField.id}`;
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Contact" &&
            (this.fileType === "Inclusion" || this.fileType === "Import")
          ) {
            if (mappingField["type"].includes("account")) {
              mappingField.label = `Account.${_.get(
                accountTemplateMapping,
                `${mappingField.id}`,
                mappingField.label
              )}`;
              mappingField.id = `account.${mappingField.id}`;
              if (!mappingField.mapping.includes(mappingField.label)) {
                mappingField.mapping.push(mappingField.label);
              }
              return true;
            }
            if (mappingField["type"].includes("contact")) {
              mappingField.label = `Contact.${_.get(
                contactTemplateMapping,
                `${mappingField.id}`,
                mappingField.label
              )}`;
              mappingField.id = `contact.${mappingField.id}`;
              if (!mappingField.mapping.includes(mappingField.label)) {
                mappingField.mapping.push(mappingField.label);
              }
              return true;
            }
          }
          if (
            this.selectedImportType === "Account" &&
            this.fileType === "Suppression" &&
            mappingField["type"].includes("accountSuppression")
          ) {
            mappingField.label = _.get(
              accountTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            );
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Contact" &&
            this.fileType === "Suppression" &&
            mappingField["type"].includes("contactSuppression")
          ) {
            mappingField.label = _.get(
              contactTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            );
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Task" &&
            this.fileType === "Import" &&
            mappingField["type"].includes("task")
          ) {
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType == "Account Contact" &&
            this.fileType == "Import" &&
            mappingField["type"].includes("accountContact")
          ) {
            if (mappingField["type"].includes("account")) {
              mappingField.label = `Account.${_.get(
                accountTemplateMapping,
                `${mappingField.id}`,
                mappingField.label
              )}`;
              mappingField.id = `account.${mappingField.id}`;
              if (!mappingField.mapping.includes(mappingField.label)) {
                mappingField.mapping.push(mappingField.label);
              }
              return true;
            }
            if (mappingField["type"].includes("contact")) {
              mappingField.label = `Contact.${_.get(
                contactTemplateMapping,
                `${mappingField.id}`,
                mappingField.label
              )}`;
              mappingField.id = `contact.${mappingField.id}`;
              if (!mappingField.mapping.includes(mappingField.label)) {
                mappingField.mapping.push(mappingField.label);
              }
              return true;
            }
          }
        }
      );
    },
    mappingColumn: function (fileHeader, value) {
      if (value) {
        this.mapping[fileHeader] = value["id"];
        this.checkMapping();
      } else {
        this.mapping[fileHeader] = "";
      }
    },
    enableJumpMappingBtn: function () {
      this.disabledJumpMappingBtn = !(
        this.projectName &&
        this.fileType &&
        this.selectedImportType &&
        this.file &&
        this.fileParseComplete &&
        !this.totalErrorFileParse
      );
    },
    checkForSupportFileError: function () {
      if (!this.isAllowedFileType) {
        this.importFileIcon = "close";
        this.importFileIconColor = "warning";
        let notification = {
          type: "warning",
          showMessage: true,
          message: this.$notifications().FILE_CONTENT_TYPE_ERROR,
        };
        this.setNotification(notification);
        this.parsingError = {
          code: "Restricted File Type",
          message:
            "File Must Be In .csv,.pdf,.doc,.docx,.odf,.xsl,.xlsx,.png,.jpg,.jpeg Format",
          row: -1,
          type: "Restricted",
        };
      } else {
        this.parsingError = {};
      }
    },
    enableSupportFileUploadBtn: function () {
      if (!this.isAllowedFileType) {
        this.disabledSupportFileUploadBtn = true;
      } else {
        this.disabledSupportFileUploadBtn = !(
          this.projectName &&
          this.fileType &&
          this.file
        );
      }
    },
    changeImportFileIcon: function () {
      if (!this.isAllowedFileType) {
        this.importFileIcon = "close";
        this.importFileIconColor = "warning";
        let notification = {
          type: "warning",
          showMessage: true,
          message: this.$notifications().FILE_CONTENT_TYPE_ERROR,
        };
        this.setNotification(notification);
      } else if (this.columnIsDuplicate) {
        this.importFileIcon = "close";
        this.importFileIconColor = "warning";
        let notification = {
          type: "warning",
          showMessage: true,
          message: this.$notifications().FILE_UPLOAD_HEADER_ERROR,
        };
        this.setNotification(notification);
      } else if (
        this.file &&
        this.fileParseComplete &&
        this.totalErrorFileParse
      ) {
        this.importFileIcon = "close";
        this.importFileIconColor = "warning";
      } else if (
        this.file &&
        this.fileParseComplete &&
        !this.totalErrorFileParse
      ) {
        this.importFileIcon = "check";
        this.importFileIconColor = "success";
        this.enableJumpMappingBtn();
      } else {
        this.importFileIcon = "attach_file";
        this.importFileIconColor = "primary";
      }
    },
    initMapping: function () {
      this.stepperStepNo = 2;
      this.autoMappingFields();
    },
    autoMappingFields: function () {
      this.mapping = {};
      let fileHeadersInLowerCase = {};

      this.fileHeaders.forEach((header) => {
        fileHeadersInLowerCase[header.toLocaleLowerCase().trim()] = header;
        this.mapping[header] = "";
      });

      for (let index = 0; index < this.filteredMappingFields.length; index++) {
        const mappingField = this.filteredMappingFields[index];
        if (!mappingField["mapping"].length) {
          continue;
        }
        mappingField["mapping"].forEach((fieldName) => {
          if (fileHeadersInLowerCase[fieldName.toLowerCase()]) {
            let matchedField = fileHeadersInLowerCase[fieldName.toLowerCase()];
            this.mapping[matchedField] = mappingField["id"];
          }
        });
      }
      this.$forceUpdate();
    },
    checkMappingAndProceed() {
      if (this.checkMapping()) {
        this.stepperStepNo = 3;
      }
    },
    checkMapping() {
      const scenario = this.mappingScenarios.find(
        (s) =>
          s.selectedImportType === this.selectedImportType &&
          s.fileType === this.fileType
      );
      if (!scenario) {
        this.mappingError = null;
        return true;
      }
      let values = Object.values(this.mapping);
      const missingFields = scenario.requiredFields.filter(
        (field) => !values.includes(field)
      );
      if (missingFields.length > 0) {
        this.mappingError = missingFields.join(", ");
        return false;
      }
      this.mappingError = null;
      return true;
    },
    onFileParsingComplete: function (results) {
      this.fileParseComplete = true;
      this.fileHeaders = results.meta.fields;
      this.countFileRow = results.data.length;
      this.totalErrorFileParse = results.errors.length;
      var tempArray = this.fileHeaders.map((x) =>
        typeof x === "string" ? x.toLowerCase() : x
      );
      tempArray = Array.from(new Set(tempArray));
      this.columnIsDuplicate =
        tempArray.length === this.fileHeaders.length ? false : true;
      this.changeImportFileIcon();
      this.showParsingError(results);
    },
    onFileParsingError: function (results) {
      this.fileParseComplete = true;
      this.totalErrorFileParse = results.errors.length;
      this.changeImportFileIcon();
      this.showParsingError(results);
    },
    selectSupportFile: function () {
      this.isAllowedFileType = true;

      if (this.file) {
        this.checkContentTypeBasedOnFileType(this.file, this.fileType);
        this.importFileIcon = "check";
        this.importFileIconColor = "success";
      } else {
        this.importFileIcon = "attach_file";
        this.importFileIconColor = "primary";
      }
      this.enableSupportFileUploadBtn();
      this.checkForSupportFileError();
    },
    checkSuppressionFileExistance: async function (fileName) {
      try {
        const suppressionFileExitanceRes =
          await Api.checkSuppressionFileExistance(
            fileName,
            this.clientId,
            this.projectId
          );
        return suppressionFileExitanceRes.data.isSuppressionFileExists;
      } catch (error) {
        console.log(
          `Suppression File ${fileName} Check Failed With Error ${error}`
        );
      }
    },
    checkContentTypeBasedOnFileType: async function (file, fileType) {
      if (
        ["Suppression", "Inclusion"].includes(fileType) &&
        file.type !== "text/csv"
      ) {
        this.isAllowedFileType = false;
      } else if (
        fileType === "Supporting Document" &&
        [
          "image/svg+xml",
          "text/html",
          "application/x-php",
          "text/javascript",
          "application/x-shellscript",
          "application/x-msdownload",
          "application/octet-stream",
          "application/x-msdos-program",
          "application/x-msdos-program",
          "application/json",
        ].includes(file.type)
      ) {
        this.isAllowedFileType = false;
      }
    },
    selectFile: async function () {
      this.suppressionFileExistanceMsg = "";
      this.columnIsDuplicate = false;
      this.isAllowedFileType = true;
      this.fileParseComplete = false;
      this.countFileRow = 0;
      this.totalErrorFileParse = 0;
      this.parsingError = {};
      this.changeImportFileIcon();
      this.enableJumpMappingBtn();
      if (this.file) {
        this.mappingError = null;
        this.checkContentTypeBasedOnFileType(this.file, this.fileType);
        let isSuppressionFileExists = false;
        if (this.fileType === "Suppression") {
          isSuppressionFileExists = await this.checkSuppressionFileExistance(
            this.file.name
          );
        }
        if (!isSuppressionFileExists) {
          this.$papa.parse(this.file, {
            header: true,
            delimiter: ",",
            skipEmptyLines: "greedy",
            complete: this.onFileParsingComplete,
            error: this.onFileParsingError,
          });
          this.selectedFileName = this.file.name;
        } else {
          this.suppressionFileExistanceMsg = `Suppression File "${this.file.name}" Already Exists, Please Rename and Upload File`;
        }
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    makeRequest: function (method, url, contentType, body, isAsync = true) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(method, url, isAsync);
        xhr.setRequestHeader("Content-Type", contentType);
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve({
              status: this.status,
              statusText: this.statusText,
            });
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.send(body);
      });
    },
    uploadFile: async function () {
      this.disabledFileUploadBtn = true;
      this.disabledSupportFileUploadBtn = true;
      this.showFileUploadProgress = true;

      let config = {
        onUploadProgress: function (progressEvent) {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      this.operationName = _.camelCase(
        `${this.selectedImportType}${this.fileType}`
      );

      const fileData = {
        projectId: this.projectId,
        operationName: this.operationName,
        mapping: JSON.stringify(this.mapping),
        rowCount: this.countFileRow,
        fileType: this.fileType,
        fileName: this.file.name,
        fileContentType: this.file.type,
      };

      if (this.importType == "Task") {
        fileData.taskTypeId = this.selectedTaskType;
        fileData.objectType = this.selectedObjectType.toLocaleLowerCase();
      }

      const self = this;
      let fileId;
      let uploadURL;

      // get the signedURL to upload a File
      try {
        const result = await Api.uploadFile(fileData, config);

        uploadURL = result.data.uploadUrl;
        fileId = result.data.fileId;
      } catch (error) {
        // Get File Upload URL Failed
        // Show Error Notification
        self.disabledFileUploadBtn = false;
        self.disabledSupportFileUploadBtn = false;
        self.showFileUploadProgress = false;
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().FILE_UPLOAD_ERROR,
        };
        self.setNotification(notification);
        self.$emit("uploaded", false);
        return;
      }

      // upload File using the uploadURL
      try {
        if (!uploadURL) {
          throw new Error("Could Not Found UploadURl to Upload A file");
        }

        await self.makeRequest(
          "PUT",
          uploadURL,
          self.file.type,
          self.file,
          true
        );

        // File Upload Success
        // Show Success Notification
        self.showFileUploadProgress = false;
        self.fileUploadDialog = false;
        self.supportFileUploadDialog = false;
        let notification = {
          type: "success",
          showMessage: true,
          message: self.$notifications().FILE_UPLOADED,
        };
        self.setNotification(notification);

        self.$emit("uploaded", true);
      } catch (error) {
        // File Upload Failed
        // Delete File Entry
        try {
          await Api.deleteFile(fileId);
        } catch (e) {
          console.log(e);
        }

        // File Upload Failed
        // Show Error Notification

        self.disabledFileUploadBtn = false;
        self.disabledSupportFileUploadBtn = false;
        self.showFileUploadProgress = false;
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().FILE_UPLOAD_ERROR,
        };
        self.setNotification(notification);
        self.$emit("uploaded", false);
        return;
      }
    },
    fetchTaskTypes: function () {
      Api.getTaskTypes()
        .then((response) => {
          this.taskTypes = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchTaskTypeForProject: function () {
      Api.getTaskTypeForProject(this.projectId)
        .then((response) => {
          this.selectedTaskType = response.data.id;

          this.selectedObjectType =
            this.mappingObjectType[this.selectedTaskType];
        })
        .catch((error) => console.log(error));
    },
    showParsingError: function (results) {
      this.parsingError = {};
      if (results.errors.length) {
        this.parsingError = results.errors[0];
      }
      if (this.columnIsDuplicate) {
        this.parsingError = {
          code: "Headers",
          message: "Duplicate Headers Found",
          row: -1,
          type: "Duplicate",
        };
      }
      if (!this.isAllowedFileType) {
        this.parsingError = {
          code: "Restricted File Type",
          message: "File Must Be In Csv Format",
          row: -1,
          type: "Restricted",
        };
      }
    },
    mappingObjectTypeField: function (selectedTaskType) {
      this.selectedObjectType = this.mappingObjectType[selectedTaskType.id];
    },
  },
};
</script>

<style scoped>
.content {
  margin-left: 70px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.header h1 {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
.label {
  align-self: center;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}
.fileDetailValue,
.mappingDetail {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #25213b;
  text-overflow: unset;
}
label.fileDetailValue {
  align-self: center;
}
.fileImportForm {
  width: 60%;
  margin: 0 auto;
}
.mappingField .v-select__selection--comma {
  overflow: visible;
}
.mappingField,
.fileDetailField {
  width: 95%;
  height: 75px;
}
.mappingField .mappingFieldLabel,
.fileDetailField .fileDetailLabel {
  margin-top: 10px;
  align-self: baseline;
}
.supportFileUpload {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  padding: 24px 24px 16px;
  width: 100%;
}
.start_import_wrapper h6 {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}
.notification {
  position: absolute;
}
.disabled_file_upload_button {
  opacity: 0.5;
  pointer-events: none;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_c('h1',[_c('router-link',{attrs:{"to":"/project-list"}},[_vm._v("Data Project Request")]),_vm._v(" > "),(_vm.isOwner)?_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.project.name)+" ")]):_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.project.aliasName)+" ")])],1)]),_c('div',{staticClass:"tabs"},[_c('ul',[_c('li',{class:{ 'is-active': 'overview' == _vm.showTab }},[_c('a',{on:{"click":function($event){return _vm.switchTab('overview')}}},[_c('span',[_vm._v("Specifications")])])]),_c('li',{class:{ 'is-active': 'settings' == _vm.showTab }},[_c('a',{on:{"click":function($event){return _vm.switchTab('settings')}}},[_c('span',[_vm._v("Setup")])])]),_c('li',{class:{ 'is-active': 'accounts' == _vm.showTab }},[_c('a',{on:{"click":function($event){return _vm.switchTab('accounts')}}},[_c('span',[_vm._v("Accounts")])])]),_c('li',{class:{ 'is-active': 'contacts' == _vm.showTab }},[_c('a',{on:{"click":function($event){return _vm.switchTab('contacts')}}},[_c('span',[_vm._v("Contacts")])])]),_c('li',{class:{ 'is-active': 'tasks' == _vm.showTab }},[_c('a',{on:{"click":function($event){return _vm.switchTab('tasks')}}},[_c('span',[_vm._v("Tasks")])])]),_c('li',{class:{ 'is-active': 'job_status' == _vm.showTab }},[_c('a',{on:{"click":function($event){return _vm.switchTab('job_status')}}},[_c('span',[_vm._v("Job Status")])])]),_c('li',{class:{ 'is-active': 'download' == _vm.showTab }},[_c('a',{on:{"click":function($event){return _vm.switchTab('download')}}},[_c('span',[_vm._v("Downloads")])])]),_c('li',{class:{
            'is-active': 'integration' == _vm.showTab,
            'disabled-tab': _vm.isIntegrationTabDisabled,
          },attrs:{"title":_vm.isIntegrationTabDisabled
              ? 'Please upload an account to access the Integration tab.'
              : ''}},[_c('a',{style:(_vm.isIntegrationTabDisabled
                ? 'pointer-events: none; cursor: not-allowed;'
                : ''),on:{"click":function($event){!_vm.isIntegrationTabDisabled && _vm.switchTab('integration')}}},[_c('span',[_vm._v("Integrations")])])])]),_c('div',{class:['tab-details', _vm.showTab]},[('overview' == _vm.showTab)?_c('div',[_c('PROJECTSpecifications',{attrs:{"projectId":_vm.$route.params.id,"projectName":_vm.projectNameForUser,"edit":_vm.editMode}})],1):_vm._e(),('job_status' == _vm.showTab)?_c('div',[_c('PROJECTJobStatus',{ref:"job_status",attrs:{"projectId":_vm.$route.params.id}})],1):_vm._e(),('download' == _vm.showTab)?_c('div',[_c('PROJECTDownload',{ref:"download",attrs:{"projectId":_vm.$route.params.id}})],1):_vm._e(),('accounts' == _vm.showTab)?_c('div',[_c('PROJECTAccount',{ref:"accounts",attrs:{"projectId":_vm.$route.params.id,"projectName":_vm.projectNameForUser}})],1):_vm._e(),('contacts' == _vm.showTab)?_c('div',[(_vm.projectType)?_c('PROJECTContact',{ref:"contacts",attrs:{"projectId":_vm.$route.params.id,"projectName":_vm.projectNameForUser,"projectType":_vm.projectType}}):_vm._e()],1):_vm._e(),('tasks' == _vm.showTab)?_c('div',[_c('Tasks',{ref:"tasks",attrs:{"projectId":_vm.$route.params.id,"projectName":_vm.projectNameForUser}})],1):_vm._e(),('settings' == _vm.showTab)?_c('div',[_c('PROJECTSetup',{attrs:{"projectId":_vm.$route.params.id}})],1):_vm._e(),('integration' == _vm.showTab)?_c('div',[_c('PROJECTIntegration',{ref:"integration",attrs:{"projectId":_vm.$route.params.id,"projectName":_vm.projectNameForUser}})],1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
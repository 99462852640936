<template>
  <v-container fluid class="job_list mr-4">
    <div>
      <!-- Refresh Button -->
      <v-btn
        v-if="!isGlobal"
        class="ml-2 refreshButton white--text"
        color="#8B83BA"
        :loading="loading"
        :disabled="loading"
        @click="pageRefresh()"
        rounded
        >refresh <v-icon>mdi-cached</v-icon></v-btn
      >

      <!-- dataGrid -->
      <DataGrid
        gridId="pmJobStatusPageId"
        gridRef="pmJobStatusPageRef"
        gridHeight="68vh"
        :headers="headers"
        :itemList="jobList"
        :loading="loading"
        :totalCount="totalCount"
        :pageNo="pageNumber"
        @fetchGrideData="fetchJobData($event)"
        @fetchButtonClickAction="fileDownloadByHeader($event)"
      />
    </div>
  </v-container>
</template>
<script>
import Api from "../services/Api";
import DataGrid from "./dataGrid.vue";

export default {
  components: {
    DataGrid,
  },
  name: "PROJECTJobStatus",
  props: {
    projectId: String,
    isGlobal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isDownloading: false,
      jobClicked: null,
      jobList: [],
      jobDetail: {},
      operationNameMap: {
        accountSuppression: "Account Suppression",
        contactSuppression: "Contact Suppression",
        accountInclusion: "Account Inclusion",
        accountImport: "Account Import",
      },
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      totalCount: 0,
      downloadLink: "",
      headers: [
        {
          value: "fileName",
          text: "File Name",
          divider: true,
          sortable: false,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
        },
        {
          value: "operation_name",
          text: "Job Name",
          divider: true,
          sortable: false,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
        },
        {
          value: "result_processed",
          text: "Total Processed",
          divider: true,
          sortable: false,
          width: "125px",
          type: "number",
        },
        {
          value: "result_imported",
          text: "Imported",
          divider: true,
          sortable: false,
          width: "125px",
          type: "number",
        },
        {
          value: "result_errored",
          text: "Failed",
          divider: true,
          sortable: false,
          width: "125px",
          type: "number",
        },
        ...(!this.isGlobal
          ? [
              {
                value: "dupSupCheckSuccessCount",
                text: "Dup/Sup Check Success",
                divider: true,
                sortable: false,
                width: "125px",
                type: "number",
              },
              {
                value: "dupSupCheckErrorCount",
                text: "Dup/Sup Check Failed",
                divider: true,
                sortable: false,
                width: "125px",
                type: "number",
              },
            ]
          : []),
        {
          value: "createdAt",
          text: "Started",
          divider: true,
          sortable: false,
          width: "150px",
          type: "momentFormatDate",
        },
        {
          value: "updatedAt",
          text: "Ended",
          divider: true,
          sortable: false,
          width: "150px",
          type: "momentFormatDate",
        },
        {
          value: "status",
          text: "Status",
          divider: true,
          sortable: false,
          width: "125px",
          type: "string",
        },
        {
          value: "jobId",
          text: "Error Report",
          divider: true,
          sortable: false,
          width: "125px",
          type: "button",
          icon: "summarize",
        },
        {
          value: "jobId",
          text: "Uploaded File",
          divider: true,
          sortable: false,
          width: "125px",
          type: "button",
          icon: "file_download",
        },
        {
          value: "jobId",
          text: "Uploaded File with Ids",
          divider: true,
          sortable: false,
          width: "125px",
          type: "button",
          icon: "file_download",
        },
      ],
    };
  },
  mounted: function () {
    this.fetchJobList();
  },

  methods: {
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    pageRefresh: async function () {
      this.loading = true;
      await this.fetchJobList();
      this.loading = false;
    },
    fetchJobList: function () {
      let self = this;
      let emptyUpdatedAtJobStatus = [
        "Queued",
        "Processing",
        "Preparing",
        "Verifying",
        "Verified",
      ];
      this.loading = true;
      let params = {
        sort: { createdAt: "desc" },
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
      };
      let apiCall = this.isGlobal
        ? Api.getGlobalJobList(params)
        : Api.getJobList(this.projectId, {
            ...params,
            fileStatus: ["upload", "taskAllocation"],
          });

      return apiCall
        .then((response) => {
          self.totalCount = response.data.totalCount;
          self.jobList = response.data.docs;
          //added property to itemInfo in dataGrid
          self.jobList.map((job) => {
            //Pass header text to itemInfo the icon
            job.itemInfo = {};
            if (
              job.status === "Completed" ||
              job.status === "Failed" ||
              job.status === "Cancelled" ||
              job.status === "Processing"
            ) {
              job.itemInfo.header = "Uploaded File";
              job.itemInfo.isDisableButton = false;
            } else {
              job.itemInfo.header = "Uploaded File";
              job.itemInfo.isDisableButton = true;
            }

            if (emptyUpdatedAtJobStatus.indexOf(job.status) > -1) {
              job.updatedAt = "";
            }
          });
          this.loading = false;
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().STATUS_SUCCESS,
          };
          self.setNotification(notification);
        })
        .catch(() => {
          this.loading = false;
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().STATUS_ERROR,
          };
          self.setNotification(notification);
        });
    },
    downloadErrorFile: function (jobId) {
      let apiCall = this.isGlobal
        ? Api.downloadGlobalErrorFile(jobId, {
            download: true,
            isGlobal: true,
          })
        : Api.downloadErrorFile(this.projectId, jobId, {
            download: true,
          });

      return apiCall
        .then((response) => {
          let fileDownloadLink = response.data.signedUrl;
          window.open(fileDownloadLink, "_blank");
          let notification = {
            type: "success",
            showMessage: true,
            message: this.$notifications().ERROR_REPORT_DOWNLOAD_SUCCESS,
          };
          this.setNotification(notification);
        })
        .catch(() => {
          let notification = {
            type: "error",
            showMessage: true,
            message: this.$notifications().ERROR_REPORT_DOWNLOAD_ERROR,
          };
          this.setNotification(notification);
        });
    },
    downloadJobFile: function (jobId, projectId, downloadWithId) {
      return Api.downloadJobFile(projectId, jobId, downloadWithId);
    },
    downloadFile: async function (jobId, downloadWithId = false) {
      try {
        this.jobClicked = jobId;
        this.isDownloading = true;
        const response = this.isGlobal
          ? await Api.downloadGlobalJobFile(jobId, downloadWithId)
          : await this.downloadJobFile(jobId, this.projectId, downloadWithId);
        this.isDownloading = false;
        this.jobClicked = null;
        this.downloadLink = response.data.url;
        window.open(this.downloadLink, "_blank");
        let notification = {
          type: "success",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_SUCCESS,
        };
        this.setNotification(notification);
      } catch (error) {
        this.isDownloading = false;
        this.jobClicked = null;

        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_ERROR,
        };
        this.setNotification(notification);
      }
    },
    fileDownloadByHeader: async function (data) {
      let jobId = data.value,
        headerName = data.headerName;
      if (headerName === "Error Report") {
        await this.downloadErrorFile(jobId);
      }

      if (headerName === "Uploaded File") {
        this.downloadFile(jobId);
      }

      if (headerName === "Uploaded File with Ids") {
        let downloadWithIds = true;
        this.downloadFile(jobId, downloadWithIds);
      }
    },
    fetchJobData(options) {
      const { page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      //api call
      this.loading = true;
      this.fetchJobList();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.job_list {
  width: 100%;
}

.refreshButton {
  position: absolute;
  top: 45px;
  right: 25px;
}
</style>

<template>
  <div class="settings">
    <!-- Save Integrations Button -->
    <!-- <v-btn
      class="ml-2 setup_save_btn white--text"
      color="#8B83BA"
      :loading="isSaving"
      rounded
      :disabled="isSaving"
      @click="fileNameInputDialog = true"
    >
      Save Integrations
      <v-icon right>save</v-icon>
    </v-btn> -->
    <v-dialog
      v-model="fileNameInputDialog"
      max-width="400"
      @click:outside="userProvidedFileName = ''"
    >
      <v-card>
        <v-form ref="fileNameInputDialogForm">
          <v-container class="pt-8">
            <v-text-field
              :rules="fileNameRules"
              v-model="userProvidedFileName"
              label="File Name"
              placeholder="Enter File Name Here"
              outlined
              autofocus
            ></v-text-field>
          </v-container>

          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>

            <v-btn
              class="ml-2 white--text"
              color="#8B83BA"
              @click="saveForm(projectId, userProvidedFileName)"
              :disabled="isDisable(userProvidedFileName)"
            >
              Ok
            </v-btn>

            <v-btn
              class="ml-2 white--text"
              color="#8B83BA"
              @click="
                fileNameInputDialog = false;
                userProvidedFileName = '';
              "
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-expansion-panels v-model="panel" multiple focusable>
      <v-row class="pa-4 mt-2 mb-2 justify-space-between integrations_nav">
        <v-col class="d-flex align-center pa-0">
          <div class="account_contact_count ml-2">
            Accounts Matched: {{ accountContactCount.account }}
          </div>
          <div class="account_contact_count ml-5">
            Contacts Matched: {{ accountContactCount.contact }}
          </div>
        </v-col>

        <v-col class="d-flex justify-end pa-0">
          <v-btn class="mx-auto" color="white" rounded>
            Download Preview
          </v-btn>
          <FileUpload
            fileType="Import"
            :projectName="projectName"
            :projectId="projectId"
            importType="Account Contact"
          >
            <template v-slot="{ on, attrs, triggerClick }">
              <v-btn
                class="mx-auto"
                color="white"
                rounded
                v-bind="attrs"
                v-on="on"
                @click="triggerClick"
              >
                Upload Preview
              </v-btn>
            </template>
          </FileUpload>
          <v-btn class="mx-auto" color="white" rounded>
            Push To Campaign
          </v-btn>
        </v-col>
      </v-row>
      <v-expansion-panel>
        <v-expansion-panel-header>Accounts</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" class="form">
            <v-row class="mt-0 mb-0">
              <v-btn
                class="ml-2 integrations_save"
                color="white"
                :loading="isSaving"
                rounded
                :disabled="isSaving"
                @click="fileNameInputDialog = true"
              >
                Save Account Integrations
                <v-icon right>save</v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-4 mb-4">
              <div class="column first">
                <!-- Keywords label -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label">Keywords</label>
                </v-col>
                <!-- Keywords combobox -->
                <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
                  <v-combobox
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .keywords.value
                    "
                    chips
                    clearable
                    deletable-chips
                    multiple
                    hide-details
                    outlined
                    dense
                  ></v-combobox>
                </v-col>

                <!-- Company Type label -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label">Company Type</label>
                </v-col>
                <!-- Company Type autocomplete -->
                <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .type.value
                    "
                    chips
                    :items="accountTypeList"
                    clearable
                    deletable-chips
                    multiple
                    hide-details
                    label="Select Company Type"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- Employee Size label -->
                <v-col class="d-flex pt-0 mt-4 pb-1" cols="12" sm="12">
                  <label class="label">Employee Size</label>
                </v-col>
                <!-- Employee Size textField -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-text-field
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .employeeSize.value[0]
                    "
                    outlined
                    type="number"
                    min="0"
                    label="Min"
                    hide-details
                    oninput="if(this.value < 1) this.value = null;"
                    @change="
                      handleOperators(
                        projectSetting.masterImportOperationParam.account.filter
                          .employeeSize.value,
                        'employeeSize'
                      )
                    "
                    dense
                  ></v-text-field>
                  <v-text-field
                    class="ml-5"
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .employeeSize.value[1]
                    "
                    outlined
                    type="number"
                    hide-details
                    oninput="if(this.value < 1) this.value = null;"
                    @change="
                      handleOperators(
                        projectSetting.masterImportOperationParam.account.filter
                          .employeeSize.value,
                        'employeeSize'
                      )
                    "
                    label="Max"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Employee Size Range Label -->
                <v-col class="d-flex pt-0 mt-4 pb-1" cols="12" sm="12">
                  <label class="label">Employee Size Range</label>
                </v-col>
                <!-- Employee Size Range autocomplete -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .employeeSizeRange.value
                    "
                    chips
                    :items="employeeSizeRangeList"
                    clearable
                    deletable-chips
                    multiple
                    hide-details
                    label="Select Employee Range"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- Revenue Label -->
                <v-col class="d-flex pt-0 mt-4 pb-1" cols="12" sm="12">
                  <label class="label">Revenue</label>
                </v-col>
                <!-- Revenue text-field -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-text-field
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .revenue.value[0]
                    "
                    outlined
                    type="number"
                    label="Min"
                    hide-details
                    oninput="if(this.value < 1) this.value = null;"
                    @change="
                      handleOperators(
                        projectSetting.masterImportOperationParam.account.filter
                          .revenue.value,
                        'revenue'
                      )
                    "
                    min="0"
                    dense
                  ></v-text-field>
                  <v-text-field
                    class="ml-5"
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .revenue.value[1]
                    "
                    outlined
                    type="number"
                    hide-details
                    oninput="if(this.value < 1) this.value = null;"
                    @change="
                      handleOperators(
                        projectSetting.masterImportOperationParam.account.filter
                          .revenue.value,
                        'revenue'
                      )
                    "
                    label="Max"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Revenue Range -->
                <v-col class="d-flex pt-0 mt-4 pb-1" cols="12" sm="12">
                  <label class="label">Revenue Range</label>
                </v-col>
                <!-- Revenue Range autocomplete -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .revenueRange.value
                    "
                    chips
                    :items="revenueRangeList"
                    clearable
                    deletable-chips
                    multiple
                    label="Select Revenue Range"
                    outlined
                    hide-details
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- SIC Code Label -->
                <v-col class="d-flex pt-0 mt-4 pb-1" cols="12" sm="12">
                  <label class="label">SIC Code</label>
                </v-col>
                <!-- SIC Code autocomplete-->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .sicCode.value
                    "
                    chips
                    :items="sicList"
                    clearable
                    hide-details
                    deletable-chips
                    multiple
                    item-text="fullTitle"
                    item-value="id"
                    label="Choose SIC Code"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- NAICS Code Label -->
                <v-col class="d-flex pt-0 mt-4 pb-1" cols="12" sm="12">
                  <label class="label">NAICS Code</label>
                </v-col>
                <!-- NAICS Code autocomplete -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .naicsCode.value
                    "
                    chips
                    :items="naicsList"
                    clearable
                    deletable-chips
                    multiple
                    hide-details
                    item-text="fullTitle"
                    item-value="id"
                    label="Choose NAICS Code"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- Technology Label -->
                <v-col class="d-flex pt-0 mt-4 pb-1" cols="12" sm="12">
                  <label class="label">Technology</label>
                </v-col>
                <!-- Technology Label autocomplete -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .technology.value
                    "
                    chips
                    :items="technologyList"
                    clearable
                    :search-input.sync="techSearch"
                    fetchTechnology
                    deletable-chips
                    multiple
                    hide-details
                    label="Choose Technology"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <hr class="mb-3" />

                <!-- Type of Location Radio -->
                <v-col class="d-flex pt-0 mt-5 pb-1" cols="12" sm="12">
                  <v-card class="col-sm-12">
                    <v-radio-group
                      class="mt-0"
                      hide-details
                      v-model="locationSearchType"
                      row
                    >
                      <template v-slot:label>
                        <label data-v-67dbb1ab="" class="label col-sm-12 pt-0"
                          >Type of Location</label
                        >
                      </template>
                      <v-radio label="Country" value="country"></v-radio>
                      <v-radio
                        label="ZipCode + Country"
                        value="zipcode"
                      ></v-radio>
                    </v-radio-group>
                  </v-card>
                </v-col>

                <!-- Country Label-->
                <v-col
                  v-if="locationSearchType === 'country'"
                  class="d-flex pt-0 mt-6 pb-1"
                  cols="12"
                  sm="12"
                >
                  <label class="label">Country</label>
                </v-col>

                <!-- CountrySearch -->
                <v-col
                  class="d-flex pt-0 mt-1 pb-1 country_treeview"
                  cols="12"
                  sm="12"
                  v-if="locationSearchType === 'country'"
                >
                  <v-card class="mx-auto" style="width: 100%">
                    <v-sheet class="pa-4 lighten-2" color="#205fac">
                      <v-text-field
                        v-model="country.search"
                        label="Search Country"
                        dark
                        flat
                        dense
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        @input="handleCountrySearch"
                      ></v-text-field>
                    </v-sheet>
                    <v-card-text>
                      <div
                        v-if="
                          projectSetting.masterImportOperationParam.account
                            .filter.country.value.length
                        "
                      >
                        <v-card-subtitle class="pa-0">Country:</v-card-subtitle>
                        <v-chip-group active-class="primary--text" column>
                          <v-chip
                            v-for="country in projectSetting
                              .masterImportOperationParam.account.filter.country
                              .value"
                            :key="country"
                            close
                            @click:close="removeFilter('country', country)"
                            style="text-transform: capitalize"
                          >
                            {{ country }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                      <v-treeview
                        ref="countryTree"
                        v-model="country.selection"
                        :items="countryList"
                        selection-type="leaf"
                        :search="country.search"
                        :filter="countryFilter"
                        @input="splitCountryFilters()"
                        item-key="name"
                        item-text="name"
                        return-object
                        open-on-click
                        selectable
                        activatable
                      >
                        <template v-slot:label="{ item }">
                          <span style="text-transform: capitalize">{{
                            item.name
                          }}</span>
                        </template>
                      </v-treeview>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  class="d-flex pt-0 mt-5 pb-1"
                  cols="12"
                  sm="12"
                  v-if="
                    showStateFilter.display && locationSearchType === 'country'
                  "
                >
                  <label class="label">State (US)</label>
                </v-col>

                <!-- country -->
                <v-col
                  class="d-flex pt-0 mt-2 pb-1"
                  cols="12"
                  sm="12"
                  v-if="
                    showStateFilter.display && locationSearchType === 'country'
                  "
                >
                  <v-autocomplete
                    v-model="
                      projectSetting.masterImportOperationParam.account.filter
                        .state.value
                    "
                    chips
                    dense
                    :items="stateList"
                    clearable
                    deletable-chips
                    multiple
                    label="Select State"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <!-- ZipCode + Country -->
                <v-col
                  class="d-flex pt-0 mt-5 pb-1"
                  cols="12"
                  sm="12"
                  v-if="locationSearchType === 'zipcode'"
                >
                  <label class="label">ZipCode + Country</label>
                </v-col>
                <div v-if="locationSearchType === 'zipcode'" class="col-sm-12">
                  <v-card class="py-4">
                    <v-col
                      class="d-flex pt-0 mt-2 pb-1 zipCountryRow"
                      cols="12"
                      sm="12"
                      v-for="(locationField, i) in locationFields"
                      :key="i"
                    >
                      <v-col cols="12" sm="11">
                        <v-select
                          class="col-sm-12"
                          v-model="locationField.countryValue"
                          dense
                          :items="countryListArray"
                          clearable
                          label="Country"
                          outlined
                          hide-details
                        >
                          <template slot="selection" slot-scope="data">
                            <span style="text-transform: capitalize">{{
                              data.item
                            }}</span>
                          </template>
                          <template slot="item" slot-scope="data">
                            <span style="text-transform: capitalize">{{
                              data.item
                            }}</span>
                          </template>
                        </v-select>
                        <v-combobox
                          class="col-sm-12 mt-5"
                          v-model="locationField.zipCodeValue"
                          chips
                          dense
                          clearable
                          deletable-chips
                          multiple
                          label="ZipCode"
                          :disabled="!locationField.countryValue"
                          outlined
                          @change="splitCountryZipFilters"
                          hide-details
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="1">
                        <v-btn icon @click="removeZipCode(i)"
                          ><v-icon color="error">close</v-icon></v-btn
                        >
                      </v-col>
                    </v-col>
                    <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                      <v-btn @click="addZipCode" class="primary ml-3"
                        >add zipcode</v-btn
                      >
                    </v-col>
                  </v-card>
                </div>
                <hr class="mb-3" />

                <!-- Limit Label -->
                <v-col class="d-flex pt-0 mt-0 pb-1" cols="12" sm="12">
                  <label class="label col-sm-6 col-12 pa-0"
                    >Limit <span style="color: #ff0000">*</span></label
                  >
                </v-col>

                <!-- Limit textField-->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-text-field
                    class="col-sm-6 col-12"
                    v-model="
                      projectSetting.masterImportOperationParam.account.limit
                    "
                    outlined
                    type="number"
                    hide-details
                    placeholder="Max Limit is 5000"
                    label="Limit"
                    dense
                    max="5000"
                    :rules="[rules.limitRequired, rules.limitMax]"
                  ></v-text-field>
                </v-col>

                <!-- sort & order -->
                <v-col class="d-flex pt-0 mt-3 pb-1" cols="12" sm="12">
                  <label class="label col-sm-6 col-12 pa-0"
                    >Sort <span style="color: #ff0000">*</span></label
                  >
                  <label class="label col-sm-6 col-12 ml-4 pa-0"
                    >Order <span style="color: #ff0000">*</span></label
                  >
                </v-col>

                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-select
                    class="col-sm-6 col-12"
                    v-model="
                      projectSetting.masterImportOperationParam.account.sort.key
                    "
                    :items="sortList"
                    clearable
                    hide-details
                    label="Select Sort"
                    outlined
                    :rules="[rules.sort]"
                    dense
                  >
                    <template slot="selection" slot-scope="data">
                      <span style="text-transform: capitalize">{{
                        data.item
                      }}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <span style="text-transform: capitalize">{{
                        data.item
                      }}</span>
                    </template>
                  </v-select>
                  <v-select
                    class="col-sm-6 col-12 ml-4"
                    v-model="
                      projectSetting.masterImportOperationParam.account.sort
                        .order
                    "
                    :items="['asc', 'desc']"
                    clearable
                    hide-details
                    label="Select Order"
                    outlined
                    :rules="[rules.sort]"
                    dense
                  >
                    <template slot="selection" slot-scope="data">
                      <span style="text-transform: capitalize">{{
                        data.item
                      }}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <span style="text-transform: capitalize">{{
                        data.item
                      }}</span>
                    </template>
                  </v-select>
                </v-col>
              </div>

              <!-- Industry & SubIndustry -->
              <div class="column pr-0">
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label">Industry & SubIndustry</label>
                </v-col>
                <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
                  <v-card class="mx-auto" style="width: 100%">
                    <!-- search Industry -->
                    <v-sheet class="pa-4 lighten-2" color="#205fac">
                      <v-text-field
                        v-model="industry.search"
                        label="Search Industry or Sub-Industry"
                        dark
                        dense
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        item-key="id"
                        item-label="id"
                        clear-icon="mdi-close-circle-outline"
                        @input="handleIndustrySearch"
                      ></v-text-field>
                    </v-sheet>

                    <v-card-text>
                      <!-- Industry Chips -->
                      <div
                        v-if="
                          projectSetting.masterImportOperationParam.account
                            .filter.industry.value.length
                        "
                      >
                        <v-card-subtitle class="pa-0"
                          >Industry:</v-card-subtitle
                        >
                        <v-chip-group active-class="primary--text" column>
                          <v-chip
                            v-for="industry in projectSetting
                              .masterImportOperationParam.account.filter
                              .industry.value"
                            :key="industry"
                            close
                            @click:close="removeFilter('industry', industry)"
                            style="text-transform: capitalize"
                          >
                            {{ industry }}
                          </v-chip>
                        </v-chip-group>
                      </div>

                      <!-- subIndustry Chips -->
                      <div
                        v-if="
                          projectSetting.masterImportOperationParam.account
                            .filter.subIndustry.value.length
                        "
                      >
                        <v-card-subtitle class="pa-0 pt-3"
                          >Sub Industry:</v-card-subtitle
                        >
                        <v-chip-group active-class="primary--text" column>
                          <v-chip
                            v-for="subIndustry in projectSetting
                              .masterImportOperationParam.account.filter
                              .subIndustry.value"
                            :key="subIndustry"
                            close
                            @click:close="
                              removeFilter('subIndustry', subIndustry)
                            "
                            style="text-transform: capitalize"
                          >
                            {{ subIndustry }}
                          </v-chip>
                        </v-chip-group>
                      </div>

                      <!-- industry tree -->
                      <v-treeview
                        ref="industryTree"
                        v-model="industry.selection"
                        :items="industryList"
                        selection-type="independent"
                        :search="industry.search"
                        :filter="industryFilter"
                        @input="splitIndustryFilters()"
                        item-key="name"
                        item-text="name"
                        return-object
                        selectable
                        open-on-click
                        activatable
                      >
                        <template v-slot:label="{ item }">
                          <span style="text-transform: capitalize">{{
                            item.name
                          }}</span>
                        </template>
                      </v-treeview>
                    </v-card-text>
                  </v-card>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Contacts</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="ContactForm" class="form">
            <v-row class="mt-0 mb-0">
              <v-btn
                class="ml-2 integrations_save"
                color="white"
                :loading="isSaving"
                rounded
                :disabled="isSaving"
                @click="saveContactForm(projectId)"
              >
                Save Contact Integrations
                <v-icon right>save</v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-4 mb-4">
              <div class="column first">
                <!-- Job Title label -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label">Job Title</label>
                </v-col>
                <!-- Job Title combobox -->
                <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
                  <v-combobox
                    v-model="
                      projectSetting.masterImportOperationParam.contact.filter
                        .jobTitle.value
                    "
                    chips
                    clearable
                    deletable-chips
                    multiple
                    hide-details
                    placeholder="Add Job Title"
                    outlined
                    dense
                  ></v-combobox>
                </v-col>

                <!-- Job Level label -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label"
                    >Job Level <span style="color: #ff0000">*</span></label
                  >
                </v-col>
                <!-- Job Level autocomplete -->
                <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    :items="jobLevelList"
                    v-model="
                      projectSetting.masterImportOperationParam.contact.filter
                        .jobLevel.value
                    "
                    chips
                    required
                    :rules="[(v) => !!v || 'Job Level is required']"
                    clearable
                    deletable-chips
                    multiple
                    hide-details
                    outlined
                    placeholder="Choose Job Levels"
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- Job Department label -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label">Job Department</label>
                </v-col>
                <!-- Job Department autocomplete -->
                <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
                  <v-autocomplete
                    :items="jobDepartmentList"
                    v-model="
                      projectSetting.masterImportOperationParam.contact.filter
                        .jobDepartment.value
                    "
                    chips
                    clearable
                    deletable-chips
                    multiple
                    hide-details
                    outlined
                    placeholder="Choose Job Department"
                    dense
                  ></v-autocomplete>
                </v-col>

                <!-- Updated Date label -->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label">Last Modified Date (Range)</label>
                </v-col>
                <!-- Updated Date autocomplete -->
                <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
                  <v-menu
                    ref="menu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="
                      projectSetting.masterImportOperationParam.contact.filter
                        .updatedAt.value
                    "
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="
                          projectSetting.masterImportOperationParam.contact
                            .filter.updatedAt.value
                        "
                        label="Last Modified Date"
                        prepend-icon="mdi-calendar"
                        clearable
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="
                        projectSetting.masterImportOperationParam.contact.filter
                          .updatedAt.value
                      "
                      no-title
                      range
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menu.save(
                            projectSetting.masterImportOperationParam.contact
                              .filter.updatedAt.value
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <hr class="mb-3" />

                <!-- Limit Label -->
                <v-col class="d-flex pt-0 mt-0 pb-1" cols="12" sm="12">
                  <label class="label col-sm-6 col-12 pa-0"
                    >Limit <span style="color: #ff0000">*</span></label
                  >
                </v-col>

                <!-- Limit textField-->
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <v-text-field
                    class="col-sm-6 col-12"
                    v-model="
                      projectSetting.masterImportOperationParam.contact.limit
                    "
                    outlined
                    type="number"
                    hide-details
                    placeholder="Max Limit is 500"
                    label="Limit"
                    dense
                    max="500"
                    :rules="[rules.limitRequired, rules.limitMaxContact]"
                  ></v-text-field>
                </v-col>
              </div>

              <!-- Country -->
              <div class="column pr-0">
                <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
                  <label class="label">Country</label>
                </v-col>

                <!-- CountrySearch -->
                <v-col
                  class="d-flex pt-0 mt-1 pb-1 country_treeview"
                  cols="12"
                  sm="12"
                >
                  <v-card class="mx-auto" style="width: 100%">
                    <v-sheet class="pa-4 lighten-2" color="#205fac">
                      <v-text-field
                        v-model="contactCountry.search"
                        label="Search Country"
                        dark
                        flat
                        dense
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        @input="handleContactCountrySearch"
                      ></v-text-field>
                    </v-sheet>
                    <v-card-text>
                      <div
                        v-if="
                          projectSetting.masterImportOperationParam.contact
                            .filter.country.value.length
                        "
                      >
                        <v-card-subtitle class="pa-0">Country:</v-card-subtitle>
                        <v-chip-group active-class="primary--text" column>
                          <v-chip
                            v-for="country in projectSetting
                              .masterImportOperationParam.contact.filter.country
                              .value"
                            :key="country"
                            close
                            @click:close="
                              removeFilter('contactCountry', country)
                            "
                            style="text-transform: capitalize"
                          >
                            {{ country }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                      <v-treeview
                        ref="contactCountryTree"
                        v-model="contactCountry.selection"
                        :items="contactCountryList"
                        selection-type="leaf"
                        :search="contactCountry.search"
                        :filter="contactCountryFilter"
                        @input="splitContactCountryFilters()"
                        item-key="name"
                        item-text="name"
                        return-object
                        open-on-click
                        selectable
                        activatable
                      >
                        <template v-slot:label="{ item }">
                          <span style="text-transform: capitalize">{{
                            item.name
                          }}</span>
                        </template>
                      </v-treeview>
                    </v-card-text>
                  </v-card>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import Api from "../services/Api";
import _ from "lodash";
import MasterApi from "../services/MasterApi";
import FileUpload from "./fileUpload";

export default {
  name: "PROJECTIntegration",
  props: {
    projectId: String,
    projectName: String,
  },
  components: {
    FileUpload,
  },
  computed: {
    industryFilter() {
      return (item, search, textKey) => {
        return item[textKey].indexOf(this.industry.search) > -1;
      };
    },
    contactCountryFilter() {
      return (item, search, textKey) => {
        return item[textKey].indexOf(this.contactCountry.search) > -1;
      };
    },
    countryFilter() {
      return (item, search, textKey) => {
        return item[textKey].indexOf(this.country.search) > -1;
      };
    },
    combinedFilter() {
      return {
        accountFilter:
          this.projectSetting.masterImportOperationParam.account.filter,
        contactFilter:
          this.projectSetting.masterImportOperationParam.contact.filter,
      };
    },
  },
  data() {
    return {
      dateMenu: false,
      fileNameInputDialog: false,
      userProvidedFileName: "",
      fileNameRules: [(v) => !!_.trim(v) || "Please Enter Valid File Name"],
      techSearch: null,
      zipList: [],
      accountTypeList: ["Parent", "Subsidiary", "Independent"],
      zipListDisableAdd: true,
      showStateFilter: {
        name: "united states",
        display: false,
      },
      isSaving: false,
      panel: [0, 1],
      projectSetting: {
        masterImportOperationParam: {
          account: {
            filter: {
              keywords: {
                operator: "=",
                value: [],
              },
              type: {
                operator: "=",
                value: [],
              },
              employeeSize: {
                operator: "between",
                value: [],
              },
              employeeSizeRange: {
                operator: "=",
                value: [],
              },
              revenue: {
                operator: "between",
                value: [],
              },
              revenueRange: {
                operator: "=",
                value: [],
              },
              sicCode: {
                operator: "=",
                value: [],
              },
              naicsCode: {
                operator: "=",
                value: [],
              },
              technology: {
                operator: "=",
                value: [],
              },
              industry: {
                operator: "=",
                value: [],
              },
              subIndustry: {
                operator: "=",
                value: [],
              },
              country: {
                operator: "=",
                value: [],
              },
              state: {
                operator: "=",
                value: [],
              },
              countryZip: {
                operator: "=",
                value: [],
              },
            },
            limit: "",
            sort: {
              key: "",
              order: "",
            },
          },
          contact: {
            filter: {
              jobTitle: {
                operator: "=",
                value: [],
              },
              jobLevel: {
                operator: "=",
                value: [],
              },
              jobDepartment: {
                operator: "=",
                value: [],
              },
              country: {
                operator: "=",
                value: [],
              },
              updatedAt: {
                operator: "between",
                value: [],
              },
            },
            limit: 0,
          },
        },
      },
      locationSearchType: "country",
      revenueRangeList: [],
      employeeSizeRangeList: [],
      sicList: [],
      naicsList: [],
      technologyList: [],
      jobLevelList: [],
      jobDepartmentList: [],
      industry: {
        search: null,
        selection: [],
      },
      country: {
        search: null,
        selection: [],
      },
      contactCountry: {
        search: null,
        selection: [],
      },
      industryList: [],
      countryList: [],
      contactCountryList: [],
      locationFields: [
        {
          countryValue: "",
          zipCodeValue: [],
        },
      ],
      countryListArray: [],
      stateList: [],
      sortList: ["employeeSize", "revenue"],
      rules: {
        sort: (value) => !!value || "Sort is Required.",
        limitRequired: (value) => !!value || "Limit is mandatory",
        limitMax: (value) => value <= 5000 || "Max Limit is 5000 records",
        limitMaxContact: (value) => value <= 500 || "Max Limit is 500 records",
      },
      accountContactCount: {
        account: 0,
        contact: 0,
      },
    };
  },
  created: async function () {},
  mounted: function () {
    this.fetchProject(this.projectId);
    this.fetchFacets();
  },
  watch: {
    techSearch(val) {
      val ? this.fetchTechnology(val) : this.fetchTechnology("");
    },
    combinedFilter: {
      handler: _.debounce(function () {
        this.fetchAccountContactCount(this.projectId);
      }, 500),
      deep: true,
    },
  },
  methods: {
    isDisable: (input) => !input || _.trim(input) === "",
    splitCountryZipFilters() {
      let countryZipList = [];
      this.locationFields.forEach((field) => {
        let countryItem = {};
        countryItem[field.countryValue] = field.zipCodeValue;
        countryZipList.push(countryItem);
      });
      this.projectSetting.masterImportOperationParam.account.filter.countryZip.value =
        countryZipList;
      this.zipListDisableAdd = false;
    },
    getCountryZipSelection: function () {
      let finalCountryZipSelection = [];
      this.projectSetting.masterImportOperationParam.account.filter.countryZip.value.forEach(
        (item) => {
          let locationKey = Object.keys(item)[0];
          finalCountryZipSelection.push({
            countryValue: locationKey,
            zipCodeValue: item[locationKey],
          });
        }
      );
      this.locationFields = finalCountryZipSelection;
      if (this.locationFields.length) {
        this.zipListDisableAdd = false;
      }
    },
    addZipCode() {
      this.locationFields.push({
        countryValue: "",
        zipCodeValue: "",
      });
      this.zipListDisableAdd = true;
    },
    removeZipCode(index) {
      this.locationFields.splice(index, 1);
      this.zipListDisableAdd = false;
    },
    handleIndustrySearch: function (val) {
      if (val) {
        this.$refs.industryTree.updateAll(true);
      } else {
        this.$refs.industryTree.updateAll(false);
      }
    },
    handleCountrySearch: function (val) {
      if (val) {
        this.$refs.countryTree.updateAll(true);
      } else {
        this.$refs.countryTree.updateAll(false);
      }
    },
    handleContactCountrySearch: function (val) {
      if (val) {
        this.$refs.contactCountryTree.updateAll(true);
      } else {
        this.$refs.contactCountryTree.updateAll(false);
      }
    },
    handleOperators: function (val, type) {
      let operator = "";
      let value = [];
      if (!val.length) {
        return;
      }

      if (val[0] && !val[1]) {
        operator = ">";
        value = [val[0], null];
      }

      if (!val[0] && val[1]) {
        operator = "<";
        value = [null, val[1]];
      }

      if (val[0] && val[1]) {
        operator = "between";
        let numbersRange = {
          min: parseInt(val[0]),
          max: parseInt(val[1]),
        };

        if (numbersRange.min >= numbersRange.max) {
          numbersRange.max = numbersRange.min + 1;
        }

        value = [numbersRange.min, numbersRange.max];
      }

      this.projectSetting.masterImportOperationParam.account.filter[
        type
      ].operator = operator;
      this.projectSetting.masterImportOperationParam.account.filter[
        type
      ].value = value;
    },
    splitContactCountryFilters: function () {
      this.projectSetting.masterImportOperationParam.contact.filter[
        "country"
      ].value = [];
      this.contactCountry.selection.forEach((item) => {
        if (
          !_.includes(
            this.projectSetting.masterImportOperationParam.contact.filter[
              "country"
            ].value,
            item.name
          )
        ) {
          this.projectSetting.masterImportOperationParam.contact.filter[
            "country"
          ].value.push(item.name);
        }
      });
      this.getContactCountrySelection();
    },
    splitCountryFilters: function () {
      this.projectSetting.masterImportOperationParam.account.filter[
        "country"
      ].value = [];
      this.country.selection.forEach((item) => {
        if (
          !_.includes(
            this.projectSetting.masterImportOperationParam.account.filter[
              "country"
            ].value,
            item.name
          )
        ) {
          this.projectSetting.masterImportOperationParam.account.filter[
            "country"
          ].value.push(item.name);
        }
      });
      if (
        _.includes(
          this.projectSetting.masterImportOperationParam.account.filter[
            "country"
          ].value,
          this.showStateFilter.name
        )
      ) {
        this.showStateFilter.display = true;
      } else {
        this.projectSetting.masterImportOperationParam.account.filter[
          "state"
        ].value = [];
        this.showStateFilter.display = false;
      }
      this.getCountrySelection();
    },
    removeFilter: async function (filterType, filterKey) {
      if (filterType === "contactCountry") {
        let filterIndex =
          this.projectSetting.masterImportOperationParam.contact.filter[
            filterType
          ].value.indexOf(filterKey);
        this.projectSetting.masterImportOperationParam.contact.filter[
          filterType
        ].value.splice(filterIndex, 1);
        this.getContactCountrySelection();
        return;
      }
      let filterIndex =
        this.projectSetting.masterImportOperationParam.account.filter[
          filterType
        ].value.indexOf(filterKey);
      this.projectSetting.masterImportOperationParam.account.filter[
        filterType
      ].value.splice(filterIndex, 1);
      if (filterType === "industry" || filterType === "subIndustry") {
        this.getIndustrySelection();
      }
      if (filterType === "country") {
        this.getCountrySelection();
      }
    },
    splitIndustryFilters: function () {
      this.projectSetting.masterImportOperationParam.account.filter[
        "industry"
      ].value = [];
      this.projectSetting.masterImportOperationParam.account.filter[
        "subIndustry"
      ].value = [];
      this.industry.selection.forEach((item) => {
        if (
          item.children &&
          !_.includes(
            this.projectSetting.masterImportOperationParam.account.filter[
              "industry"
            ].value,
            item.name
          )
        ) {
          this.projectSetting.masterImportOperationParam.account.filter[
            "industry"
          ].value.push(item.name);
        } else if (
          !_.includes(
            this.projectSetting.masterImportOperationParam.account.filter[
              "industry"
            ].value,
            item.name
          ) &&
          !_.includes(
            this.projectSetting.masterImportOperationParam.account.filter[
              "subIndustry"
            ].value,
            item.name
          )
        ) {
          this.projectSetting.masterImportOperationParam.account.filter[
            "subIndustry"
          ].value.push(item.name);
        }
      });
      this.getIndustrySelection();
    },
    getContactCountrySelection: function () {
      let finalCountrySelection = [];
      this.projectSetting.masterImportOperationParam.contact.filter.country.value.forEach(
        (item) => {
          finalCountrySelection.push({
            name: item,
          });
        }
      );
      this.contactCountry.selection = finalCountrySelection;
    },
    getCountrySelection: function () {
      let finalCountrySelection = [];
      this.projectSetting.masterImportOperationParam.account.filter.country.value.forEach(
        (item) => {
          finalCountrySelection.push({
            name: item,
          });
        }
      );
      this.country.selection = finalCountrySelection;
    },
    getIndustrySelection: function () {
      // Modify Filters
      let finalIndustrySelection = [];
      this.projectSetting.masterImportOperationParam.account.filter.industry.value.forEach(
        (item) => {
          finalIndustrySelection.push({
            name: item,
            children: [0],
          });
        }
      );
      this.projectSetting.masterImportOperationParam.account.filter.subIndustry.value.forEach(
        (item) => {
          finalIndustrySelection.push({
            name: item,
          });
        }
      );
      this.industry.selection = finalIndustrySelection;
    },
    fetchIndustryList: async function () {
      await MasterApi.getAllIndustry(true)
        .then((response) => {
          this.industryList = response.data;
        })
        .catch((error) => console.log(error));
      this.getIndustrySelection();
      this.splitIndustryFilters();
    },
    fetchEmployeeSizeRangeList: function () {
      this.employeeSizeRangeList =
        this.$constants().ACCOUNT_MASTER_EMPLOYEE_SIZE;
    },
    fetchRevenueRangeList: function () {
      this.revenueRangeList = this.$constants().ACCOUNT_MASTER_REVENUE;
    },
    fetchTechnology: async function (v) {
      await MasterApi.getTechnology({
        param: v,
      })
        .then((response) => {
          this.technologyList = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchSicList: async function () {
      await MasterApi.getSIC({
        values: "",
      })
        .then((response) => {
          this.sicList = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchNaicsList: async function () {
      await MasterApi.getNAICS({
        values: "",
      })
        .then((response) => {
          this.naicsList = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchStateList: function () {
      this.stateList = this.$constants().US_STATES;
    },
    extractCountries: function (countryRegion) {
      let countries = [];
      countryRegion.forEach((region) => {
        region.children.forEach((country) => {
          countries.push(country.name);
        });
      });
      return countries.sort();
    },
    fetchCountryList: async function () {
      await MasterApi.getLocations()
        .then((response) => {
          this.countryList = response.data;
          this.contactCountryList = response.data;
          this.countryListArray = this.extractCountries(this.countryList);
        })
        .catch((error) => console.log(error));
      this.getCountrySelection();
      this.splitCountryFilters();
      this.getCountryZipSelection();
      this.splitContactCountryFilters();
    },
    fetchJobLevel: async function () {
      await MasterApi.getJobLevels()
        .then((response) => {
          this.jobLevelList = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchJobDepartment: async function () {
      await MasterApi.getJobDepartments()
        .then((response) => {
          this.jobDepartmentList = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchFacets: function () {
      this.fetchEmployeeSizeRangeList();
      this.fetchRevenueRangeList();
      this.fetchSicList();
      this.fetchNaicsList();
      this.fetchIndustryList();
      this.fetchCountryList();
      this.fetchStateList();
      this.fetchTechnology("");
      this.fetchJobLevel();
      this.fetchJobDepartment();
    },
    formatFilters: function (payload) {
      let finalPayload = {};
      Object.keys(payload).forEach((filter) => {
        let payLoadFilter = _.cloneDeep(payload[filter]);
        if (filter === "countryZip" && payLoadFilter.length) {
          finalPayload[filter] = payLoadFilter;
          return;
        } else if (filter === "employeeSize" && payLoadFilter.value.length) {
          finalPayload[filter] = payLoadFilter;
          finalPayload[filter].value = _.filter(
            payLoadFilter.value,
            (v) => v !== null
          );
          finalPayload[filter].value = this.setupRangeFields(
            finalPayload[filter]
          );
        } else if (filter === "revenue" && payLoadFilter.value.length) {
          finalPayload[filter] = payLoadFilter;
          finalPayload[filter].value = _.filter(
            payLoadFilter.value,
            (v) => v !== null
          );
          finalPayload[filter].value = this.setupRangeFields(
            finalPayload[filter]
          );
        } else if (!_.isEmpty(payLoadFilter.value)) {
          finalPayload[filter] = payLoadFilter;
        }
      });
      return finalPayload;
    },
    saveForm: function (projectId, userProvidedFileName) {
      var formValid = this.$refs.form.validate();
      if (!formValid) {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
        return;
      }

      if (!userProvidedFileName) {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FILENAME_NOT_FOUND,
        };
        this.setNotification(notification);
        return;
      }
      let masterImportParams = Object.assign(
        {},
        this.projectSetting.masterImportOperationParam.account
      );

      if (this.locationSearchType === "country") {
        delete masterImportParams.filter.countryZip;
      }
      if (this.locationSearchType === "zipcode") {
        delete masterImportParams.filter.country;
        delete masterImportParams.filter.state;
      }

      masterImportParams.filter = this.formatFilters(masterImportParams.filter);

      masterImportParams.sort = {};
      masterImportParams.sort[
        this.projectSetting.masterImportOperationParam.account.sort.key
      ] = this.projectSetting.masterImportOperationParam.account.sort.order;
      masterImportParams.fileName = userProvidedFileName;

      this.isSaving = true;
      let self = this;

      return Api.editProjectIntegrations(projectId, masterImportParams)
        .then(function () {
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().PROJECT_SETUP_SUCCESS,
          };
          self.setNotification(notification);
          self.fileNameInputDialog = false;
          self.isSaving = false;
          self.fetchProject(self.projectId);
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().PROJECT_SETUP_ERROR,
          };
          self.setNotification(notification);
          self.isSaving = false;
        });
    },
    saveContactForm: function (projectId) {
      var formValid = this.$refs.ContactForm.validate();
      if (!formValid) {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
        return;
      }
      let masterImportParams = Object.assign(
        {},
        this.projectSetting.masterImportOperationParam.contact
      );
      masterImportParams.filter = this.formatFilters(masterImportParams.filter);

      this.isSaving = true;
      let self = this;

      return Api.editProjectIntegrationsContact(projectId, masterImportParams)
        .then(function () {
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().PROJECT_SETUP_SUCCESS,
          };
          self.setNotification(notification);
          self.isSaving = false;
          self.fetchProject(self.projectId);
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().PROJECT_SETUP_ERROR,
          };
          self.setNotification(notification);
          self.isSaving = false;
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    setupRangeFields: function (filter) {
      if (filter.operator === "between") {
        return filter.value;
      }
      if (filter.operator === "<") {
        return filter.value[0];
      }
      if (filter.operator === ">") {
        return filter.value[0];
      }
    },
    setRangeFields: function (filter) {
      if (filter.operator === "between") {
        return filter.value;
      }
      if (filter.operator === "<") {
        return [null, filter.value];
      }
      if (filter.operator === ">") {
        return [filter.value, null];
      }
    },
    fetchProject: function (projectId) {
      var self = this;
      return Api.getProject(projectId)
        .then((response) => {
          var emptyCheck = _.isEmpty(response.data);
          var importParamsEmptyCheck = _.isEmpty(
            response.data.ProjectSetting.masterImportOperationParam.account
          );
          var importContactParamsEmptyCheck = _.isEmpty(
            response.data.ProjectSetting.masterImportOperationParam.contact
          );
          if (!emptyCheck && !importParamsEmptyCheck) {
            let finalMergedOutputFilter = Object.assign(
              {},
              self.projectSetting.masterImportOperationParam.account.filter,
              response.data.ProjectSetting.masterImportOperationParam.account
                .filter
            );

            self.projectSetting.masterImportOperationParam.account.filter =
              finalMergedOutputFilter;
            self.projectSetting.masterImportOperationParam.account.limit =
              response.data.ProjectSetting.masterImportOperationParam.account.limit;

            self.projectSetting.masterImportOperationParam.account.sort = {};
            let sortKey = Object.keys(
              response.data.ProjectSetting.masterImportOperationParam.account
                .sort
            )[0];
            self.projectSetting.masterImportOperationParam.account.sort["key"] =
              sortKey;
            self.projectSetting.masterImportOperationParam.account.sort[
              "order"
            ] =
              response.data.ProjectSetting.masterImportOperationParam.account.sort[
                sortKey
              ];

            if (
              response.data.ProjectSetting.masterImportOperationParam.account
                .filter.countryZip
            ) {
              self.locationSearchType = "zipcode";
            } else {
              self.locationSearchType = "country";
            }

            if (
              response.data.ProjectSetting.masterImportOperationParam.account
                .filter.employeeSize
            ) {
              self.projectSetting.masterImportOperationParam.account.filter.employeeSize.value =
                self.setRangeFields(
                  response.data.ProjectSetting.masterImportOperationParam
                    .account.filter.employeeSize
                );
            }

            if (
              response.data.ProjectSetting.masterImportOperationParam.account
                .filter.revenue
            ) {
              self.projectSetting.masterImportOperationParam.account.filter.revenue.value =
                self.setRangeFields(
                  response.data.ProjectSetting.masterImportOperationParam
                    .account.filter.revenue
                );
            }
          }
          if (!emptyCheck && !importContactParamsEmptyCheck) {
            let finalMergedOutputFilter = Object.assign(
              {},
              self.projectSetting.masterImportOperationParam.contact.filter,
              response.data.ProjectSetting.masterImportOperationParam.contact
                .filter
            );

            self.projectSetting.masterImportOperationParam.contact.filter =
              finalMergedOutputFilter;
            self.projectSetting.masterImportOperationParam.contact.limit =
              response.data.ProjectSetting.masterImportOperationParam.contact.limit;
          }
        })
        .catch((error) => console.log(error));
    },
    fetchAccountContactCount: function (projectId) {
      if (!projectId) {
        console.error("projectId is required.");
        return;
      }
      let accountContactCountParams = Object.assign(
        {},
        {
          filter: Object.assign(
            {},
            this.projectSetting.masterImportOperationParam.account.filter,
            this.projectSetting.masterImportOperationParam.contact.filter
          ),
        }
      );
      accountContactCountParams.filter = this.formatFilters(
        accountContactCountParams.filter
      );
      return Api.getAccountContactCount(projectId, accountContactCountParams)
        .then((response) => {
          this.accountContactCount = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
.settings {
  margin-left: 50px;
  margin-right: 50px;
}
.settings .row {
  margin: -20px 0;
}
.form {
  padding-top: 20px;
}
.column {
  width: 48%;
  float: left;
  display: block;
  margin-left: 2.5%;
  padding-right: 30px;
}
.column.first {
  border-right: 1px solid #aaa;
  margin-left: 0;
}

.form .label {
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  padding-left: 5px;
  text-transform: uppercase !important;
}
.form section {
  padding: 9px 5px;
}
.form section input {
  height: 25px;
  margin-top: 5px;
  width: 95%;
  padding-left: 10px;
}
.form section select {
  height: 25px;
  margin-top: 5px;
  width: 95%;
  padding-left: 10px;
}

.form #target {
  display: flex;
  width: 100%;
  padding: 5px;
}
.form section #account,
.form section #contact {
  width: 50%;
  padding-left: 10px;
  padding-right: 40px;
}
.form section label {
  display: inline-block;
  min-width: 35%;
  font-weight: 400;
}
.form_actions button {
  padding: 8px 25px;
  border-radius: 3px;
  outline: 0;
  margin-right: 15px;
  background: white;
  border: 1px solid black;
  cursor: pointer;
}
.form hr {
  margin: 25px 0;
}
.form_actions button.save {
  background: #205fac;
  color: white;
}

.setup_save_btn {
  position: absolute;
  top: 45px;
  right: 25px;
}
.notification {
  position: absolute;
}

.right {
  float: right;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.v-expansion-panel-header.v-expansion-panel-header--active,
.integrations_nav {
  background: #205fac;
  color: white;
}

.integrations_save {
  position: absolute;
  top: 15px;
  right: 70px;
}

.v-expansion-panel-header.v-expansion-panel-header--active,
.account_contact_count {
  font-size: 20px;
  font-weight: 800;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: white;
}
</style>

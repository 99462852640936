<template>
  <div class="app">
    <div class="content">
      <div class="header">
        <h1>
          <router-link to="/project-list">Data Project Request</router-link> >
          <span v-if="isOwner" style="white-space: pre-wrap">
            {{ project.name }}
          </span>
          <span v-else style="white-space: pre-wrap">
            {{ project.aliasName }}
          </span>
        </h1>
      </div>
      <div class="tabs">
        <ul>
          <li :class="{ 'is-active': 'overview' == showTab }">
            <a @click="switchTab('overview')">
              <span>Specifications</span>
            </a>
          </li>
          <li :class="{ 'is-active': 'settings' == showTab }">
            <a @click="switchTab('settings')">
              <span>Setup</span>
            </a>
          </li>
          <li :class="{ 'is-active': 'accounts' == showTab }">
            <a @click="switchTab('accounts')">
              <span>Accounts</span>
            </a>
          </li>
          <li :class="{ 'is-active': 'contacts' == showTab }">
            <a @click="switchTab('contacts')">
              <span>Contacts</span>
            </a>
          </li>
          <li :class="{ 'is-active': 'tasks' == showTab }">
            <a @click="switchTab('tasks')">
              <span>Tasks</span>
            </a>
          </li>
          <li :class="{ 'is-active': 'job_status' == showTab }">
            <a @click="switchTab('job_status')">
              <span>Job Status</span>
            </a>
          </li>
          <li :class="{ 'is-active': 'download' == showTab }">
            <a @click="switchTab('download')">
              <span>Downloads</span>
            </a>
          </li>
          <li
            :class="{
              'is-active': 'integration' == showTab,
              'disabled-tab': isIntegrationTabDisabled,
            }"
            :title="
              isIntegrationTabDisabled
                ? 'Please upload an account to access the Integration tab.'
                : ''
            "
          >
            <a
              @click="!isIntegrationTabDisabled && switchTab('integration')"
              :style="
                isIntegrationTabDisabled
                  ? 'pointer-events: none; cursor: not-allowed;'
                  : ''
              "
            >
              <span>Integrations</span>
            </a>
          </li>
        </ul>
        <div :class="['tab-details', showTab]">
          <div v-if="'overview' == showTab">
            <PROJECTSpecifications
              v-bind:projectId="$route.params.id"
              :projectName="projectNameForUser"
              :edit="editMode"
            />
          </div>
          <div v-if="'job_status' == showTab">
            <PROJECTJobStatus
              ref="job_status"
              v-bind:projectId="$route.params.id"
            />
          </div>
          <div v-if="'download' == showTab">
            <PROJECTDownload
              ref="download"
              v-bind:projectId="$route.params.id"
            />
          </div>
          <div v-if="'accounts' == showTab">
            <PROJECTAccount
              v-bind:projectId="$route.params.id"
              :projectName="projectNameForUser"
              ref="accounts"
            />
          </div>
          <div v-if="'contacts' == showTab">
            <PROJECTContact
              v-if="projectType"
              v-bind:projectId="$route.params.id"
              :projectName="projectNameForUser"
              :projectType="projectType"
              ref="contacts"
            />
          </div>
          <div v-if="'tasks' == showTab">
            <Tasks
              v-bind:projectId="$route.params.id"
              :projectName="projectNameForUser"
              ref="tasks"
            />
          </div>
          <div v-if="'settings' == showTab">
            <PROJECTSetup v-bind:projectId="$route.params.id" />
          </div>
          <div v-if="'integration' == showTab">
            <PROJECTIntegration
              v-bind:projectId="$route.params.id"
              :projectName="projectNameForUser"
              ref="integration"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PROJECTSpecifications from "./PROJECTSpecifications.vue";
import PROJECTSetup from "./PROJECTSetup.vue";
import PROJECTAccount from "./PROJECTAccount.vue";
import PROJECTContact from "./PROJECTContact.vue";
import PROJECTJobStatus from "./PROJECTJobStatus.vue";
import PROJECTDownload from "./PROJECTDownload.vue";
import PROJECTIntegration from "./PROJECTIntegration.vue";
import Tasks from "./Tasks.vue";
import Api from "../services/Api";
import { getRoles } from "../roles.js";
import CONSTANTS from "../constants/constants";

export default {
  name: "PROJECTDetail",
  components: {
    PROJECTSpecifications,
    PROJECTSetup,
    PROJECTAccount,
    PROJECTContact,
    PROJECTJobStatus,
    Tasks,
    PROJECTDownload,
    PROJECTIntegration,
  },
  props: {},
  data() {
    return {
      isOwner: false,
      showTab: "",
      projectList: [],
      project: {},
      projectNameForUser: "",
      editMode: false,
      projectType: null,
      hasAccount: null,
      constants: CONSTANTS,
    };
  },
  async created() {
    let role = await getRoles();
    this.isOwner =
      role.indexOf(this.$constants().USER_ROLES.PROJECT_OWNER) > -1;
    this.showTab = this.$route.params.tab;
    this.editMode = this.$route.params.edit || false;
    this.fetchProject(this.$route.params.id, this.isOwner);
  },
  computed: {
    isIntegrationTabDisabled() {
      // return !this.hasAccount  &&
      //       this.projectType === this.constants.PROJECT_TYPE.CONTACT_BUILD_NAMED_ACCOUNT;
      return false;
    },
  },

  methods: {
    switchTab(payload) {
      this.showTab = payload;
      switch (this.showTab) {
        case "settings":
        case "overview":
        case "job_status":
        case "download":
        case "contacts":
        case "tasks":
        case "accounts":
        case "integration":
          this.$router
            .push({
              name: "PROJECTDetail",
              params: {
                tab: this.showTab,
              },
            })
            .catch((e) => e);
          // this.$refs[payload].pageRefresh();
          break;
        default:
          break;
      }
    },
    fetchProject: function (projectId, isOwner) {
      var self = this;
      return Api.getProject(projectId)
        .then((response) => {
          self.project = response.data;
          self.projectNameForUser = isOwner
            ? self.project.name
            : self.project.aliasName;

          self.projectType = self.project.ProjectType.type;
          this.checkAccounts(projectId);

          return self.project;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            let logoutDomain =
              location.protocol + "//" + location.host + "/auth/login.html";
            window.auth0Client.logout({
              returnTo: logoutDomain,
              clientId: process.env.VUE_APP_OAUTHCLIENTID,
            });
            localStorage.clear();
          }
        });
    },

    checkAccounts: function (projectId) {
      Api.checkAccountsContacts(projectId, { targetEntity: "account" })
        .then((response) => {
          this.hasAccount = response.data.hasAccount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  margin-left: 60px;
}
.header {
  float: left;
  display: block;
  width: 100%;
  margin: 15px 0 10px;
}
a {
  text-decoration: none;
}
button.btn {
  float: right;
  background: #ffffff;
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  padding: 5px 15px;
  cursor: pointer;
}
.header h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 35px;
}
.tabs li {
  list-style: none;
}
.tabs li a {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  margin-bottom: -2px;
  padding: 0.5em 1em;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
}
.tabs ul {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
}
.tabs li.is-active a {
  border: 1px solid #dbdbdb;
  border-top: 3px solid;
  border-bottom: #f7f9fb;
  color: #205fac;
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 800;
  text-decoration: none;
}

.disabled-tab {
  opacity: 0.5;
  cursor: not-allowed;
}

.tab-details {
  padding-top: 20px;
}
.tab-details.accounts,
.tab-details.contacts,
.tab-details.tasks,
.tab-details.job_status,
.tab-details.download {
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
}
</style>

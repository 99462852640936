<template>
  <div class="d-inline-block float-right ml-2">
    <v-btn
      color="#8B83BA"
      depressed
      rounded
      dark
      @click="openDialog()"
      :class="{ disabled_assign_tasks_button: isButtonDisabled }"
    >
      {{ btnText }}
      <v-icon
        v-bind:class="{ 'loader-active': buttonLoaderIcon === 'sync' }"
        right
        >{{ buttonLoaderIcon }}</v-icon
      >
    </v-btn>
    <v-dialog v-model="AssignTaskDialog" fullscreen hide-overlay>
      <v-card>
        <div class="content">
          <v-toolbar dark color="#7c7c7c">
            <v-toolbar-title>Assign Tasks</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="AssignTaskDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-stepper v-model="stepperStepNo">
            <v-stepper-header>
              <v-stepper-step :complete="stepperStepNo > 1" step="1">
                Assign Tasks
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="stepperStepNo > 2" step="2">
                Review Task Assignment
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-content step="1">
              <v-form ref="form" :v-model="true" lazy-validation>
                <v-row>
                  <v-col class="pt-0 mt-2 pb-1" cols="12" sm="6">
                    <label class="label d-block mb-5">Select Agents</label>
                    <v-autocomplete
                      v-model="selectedAgents"
                      :search-input.sync="search"
                      :items="items"
                      @change="removeAgent()"
                      item-text="fullName"
                      multiple
                      outlined
                      return-object
                      hide-details
                      label="Select from Agents"
                      dense
                    >
                      <template v-slot:selection=""></template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    class="pt-0 mt-2 pb-1"
                    cols="12"
                    sm="6"
                    v-if="selectedAgentsDisplay.length"
                  >
                    <label class="label d-block mb-3"
                      >Selected Agents for Assignment</label
                    >
                    <div class="d-block">
                      <v-chip
                        v-for="(item, index) in selectedAgentsDisplay"
                        :key="item.id"
                        close
                        color="primary"
                        class="mx-1 my-3"
                        @click:close="removeAgent(index)"
                      >
                        {{ item.fullName }}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-divider class="mt-6 pb-0 col-md-12"></v-divider>
                </v-row>
                <v-row>
                  <v-col class="pt-0 mt-2 pb-1" cols="12" sm="6">
                    <label class="d-block label mb-5">Assignment Type</label>
                    <v-select
                      v-model="assignmentType"
                      :items="assignmentTypeList"
                      :rules="[(v) => !!v || 'Assignment Type is required']"
                      label="Assignment Strategy"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="assignmentType === 'Block'"
                    class="pt-0 mt-2 pb-1"
                    cols="12"
                    sm="6"
                  >
                    <label class="d-block label mb-5">Block Size</label>
                    <v-text-field
                      v-model="blockSize"
                      type="number"
                      label="Number of Accounts"
                      outlined
                      dense
                      min="1"
                      :rules="[
                        (v) => v >= 1 || 'Minimum Block Size is 1',
                        (v) =>
                          v <= maxBlockSize ||
                          'Max cannot exceed total Account count',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-divider class="mt-6 pb-0 col-md-12"></v-divider>
                </v-row>
                <v-row>
                  <v-col class="pt-0 mt-2 pb-1" cols="12" sm="6">
                    <label class="d-block label mb-5">Limit Assignment</label>
                    <v-select
                      v-model="limitAssignment"
                      :items="limitAssignmentList"
                      label="Limit Assignment"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="limitAssignment === 'Assign Top'"
                    class="pt-0 mt-2 pb-1"
                    cols="12"
                    sm="6"
                  >
                    <label class="d-block label mb-5">Limit Size</label>
                    <v-text-field
                      v-model="limitSize"
                      type="number"
                      label="Number of Units"
                      outlined
                      dense
                      min="1"
                      :rules="[
                        (v) => v >= 1 || 'Minimum Block Size is 1',
                        (v) =>
                          v <= maxBlockSize ||
                          'Max cannot exceed total Account count',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-btn
                  class="mt-10 float-right px-15"
                  color="#8B83BA"
                  dark
                  depressed
                  @click="assignTask()"
                >
                  Assign
                </v-btn>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-toolbar-title class="ml-4 mt-4"
                >Review Task Assignments</v-toolbar-title
              >

              <DataGrid
                gridId="assignTasks"
                gridRef="assignTasksRef"
                :headers="headers"
                :itemList="assignedTasks"
                :totalCount="totalCount"
                :pageNo="pageNumber"
                :limit="10"
                @fetchGrideData="getTempData($event)"
                @updateAgent="updateAgent($event)"
                @deleteTask="deleteAllocation($event)"
                :allowAction="true"
                :agentListItems="items"
              />
              <v-dialog v-model="cancelDialog" persistent max-width="420">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="mr-2 mt-10">
                    Cancel
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    Cancel Task Assignment
                  </v-card-title>
                  <v-card-text
                    >Are you sure you want to cancel this Task
                    Assignment?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="cancelDialog = false"
                    >
                      Disagree
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="setJobStatus('Cancelled')"
                    >
                      Agree
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn
                color="#8B83BA"
                class="mt-10 white--text"
                :disabled="disableAssignTasksBtn"
                @click="setJobStatus('Verified')"
              >
                Assign Tasks
              </v-btn>
            </v-stepper-content>
          </v-stepper>
          <v-stepper v-model="stepperStepNo"> </v-stepper>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import Api from "../services/Api";
import DataGrid from "./dataGrid.vue";

export default {
  name: "AssignTask",
  props: {
    projectId: String,
    pageName: String,
    projectName: String,
    filter: Object,
    sort: Object,
    maxBlockSize: Number,
    isButtonDisabled: Boolean,
  },
  components: {
    DataGrid,
  },
  data() {
    return this.initialState();
  },
  computed: {
    clientId() {
      return this.$store.state.projectClientId;
    },
  },
  mounted: function () {
    let self = this;
    switch (this.pageName) {
      case "task":
        self.btnText = "Re-Assign Task";
        break;
      default:
        self.btnText = "Assign Task";
        break;
    }
    this.querySelections("a");
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    assignmentType() {
      this.blockSize = 1;
    },
    selectedAgents(val) {
      let agentSelectionList = [].concat(this.selectedAgentsDisplay, val);
      agentSelectionList = _.orderBy(agentSelectionList, ["fullName"], ["asc"]);
      this.selectedAgentsDisplay = _.uniqBy(agentSelectionList, "id");
    },
    assignedTasks: function () {
      if (_.isEmpty(this.assignedTasks)) {
        this.disableAssignTasksBtn = true;
        return;
      }
      this.disableAssignTasksBtn = false;
    },
  },
  methods: {
    initialState: function () {
      return {
        cancelDialog: false,
        totalCount: 0,
        pageNumber: 1,
        pageSize: 20,
        buttonLoaderIcon: "add_task",
        allowAssignTask: true,
        blockSize: "",
        items: [],
        btnText: "",
        headers: [
          {
            text: "Account Name",
            value: "accountName",
            sortable: false,
            divider: true,
          },
          {
            text: "Website",
            value: "accountWebsite",
            sortable: false,
            divider: true,
          },
          {
            text: "Agent Name",
            value: "agentName",
            sortable: false,
            divider: true,
          },
        ],
        limitAssignment: "Assign All Filtered",
        limitAssignmentList: ["Assign All Filtered", "Assign Top"],
        limitSize: "",
        assignedTasks: [],
        selectedAgents: [],
        selectedAgentsDisplay: [],
        assignmentType: "",
        assignmentTypeList: [],
        AssignTaskDialog: false,
        search: null,
        progress: 0,
        loading: false,
        stepperStepNo: 1,
        disableAssignTasksBtn: false,
      };
    },
    removeAgent: function (index) {
      if (index > -1) {
        this.selectedAgentsDisplay.splice(index, 1);
      }
      setTimeout(() => {
        this.selectedAgents = this.selectedAgentsDisplay;
      }, 0);
    },
    enableAssignTask: async function () {
      this.allowAssignTask = false;
    },
    openDialog: async function () {
      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.selectedAgents = [];
      this.selectedAgentsDisplay = [];
      this.assignmentType = "";
      this.blockSize = "";
      this.limitAssignment = "Assign All Filtered";
      this.limitSize = "";
      this.getButtonText();
    },
    getButtonText: async function (options) {
      let self = this;
      options = options || {};
      Api.getActiveJobLabel(this.projectId).then(function (response) {
        // Nas change afer integration
        self.btnText = response.data[0].label;
        self.jobId = response.data[0].jobId;
        switch (self.btnText) {
          case "Assign Task":
            self.selectedAgents = [];
            self.assignmentType = "";
            if (options.fromPage === "task") {
              self.btnText = "Re-Assign Task";
            }
            self.stepperStepNo = 1;
            if (!options.callApi) {
              self.AssignTaskDialog = true;
              Api.getTaskAllocationStrategies().then(function (stategies) {
                self.assignmentTypeList = stategies.data;
              });
            }
            self.buttonLoaderIcon = "add_task";
            break;
          case "Assigning Task":
            self.stepperStepNo = 1;
            self.buttonLoaderIcon = "sync";
            self.AssignTaskDialog = false;
            break;
          case "Creating Task":
            self.buttonLoaderIcon = "sync";
            self.AssignTaskDialog = false;
            break;
          case "Verify Assignment":
            self.stepperStepNo = 2;
            if (!options.callApi) {
              self.AssignTaskDialog = true;
              self.getTempData({
                page: 1,
                itemsPerPage: self.pageSize,
              });
            }
            self.buttonLoaderIcon = "fact_check";
            break;
        }
      });
    },
    getTempData: async function (options) {
      const { page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      let self = this;
      let params = {
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
      };
      if (self.jobId.length) {
        return Api.taskAssignTemp(self.projectId, self.jobId, params).then(
          function (response) {
            self.assignedTasks = response.data.rows;
            self.totalCount = response.data.count;
          }
        );
      }
    },
    assignTask: async function () {
      let self = this;
      let selectedAgents = _.map(self.selectedAgents, function (user) {
        return user.id;
      });

      let payload = {
        taskAllocationStrategy: self.assignmentType,
        agents: selectedAgents,
        allocationOf: self.pageName,
        limitAssignment: self.limitAssignment,
      };

      await Api.getTaskTypeForProject(self.projectId).then((response) => {
        payload["taskTypeId"] = response.data.id;
      });

      if (self.assignmentType === "Block") {
        payload["blockSize"] = self.blockSize;
      }

      if (self.limitAssignment === "Assign Top") {
        payload["limitSize"] = self.limitSize;
      }

      let notification = {
        type: "error",
        showMessage: true,
      };

      if (!payload.agents.length) {
        notification.message = this.$notifications().ADD_AGENTS_TASK_ALLOCATION;
        self.setNotification(notification);
        return false;
      }

      if (!payload.taskAllocationStrategy.length) {
        notification.message =
          this.$notifications().ADD_STRATEGY_TASK_ALLOCATION;
        self.setNotification(notification);
        return false;
      }

      let params = {
        filter: JSON.stringify(self.filter),
      };

      if (self.sort) {
        params["sort"] = JSON.stringify(self.sort);
      }

      Api.assignTask(self.projectId, payload, params)
        .then(function (response) {
          self.AssignTaskDialog = false;
          console.log(response.status);
        })
        .catch(function () {
          self.AssignTaskDialog = false;
          notification.message = self.$notifications().ASSIGN_TASK_FAILED;
          self.setNotification(notification);
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    setJobStatus(message) {
      let self = this;
      Api.setJobStatus(this.projectId, this.jobId, message).then(function (
        response
      ) {
        self.AssignTaskDialog = false;
        self.cancelDialog = false;
        console.log("Done", response);
      });
    },
    querySelections(v) {
      let self = this;
      self.loading = true;
      // Simulated ajax query
      self.items = [];
      const userRole = "agent";
      return Api.autoCompleteInterFace("userSearch", {
        param: v,
        userRole: userRole,
      }).then(function (response) {
        self.items = _.map(response.data, function (user) {
          user.fullName = user.firstName + " " + user.lastName;
          return user;
        });
        self.loading = false;
      });
    },
    updateAgent(options) {
      let self = this;
      let payload = {
        agentId: options.agent.id,
        agentName: options.agent.userName,
      };
      return Api.updateAgent(
        this.projectId,
        this.jobId,
        options.id,
        payload
      ).then(function () {
        let notification = {
          type: "success",
          showMessage: true,
          message: "Updated Agent Name Successfully",
        };
        self.setNotification(notification);
        return self.getTempData({
          page: self.pageNumber,
          itemsPerPage: self.pageSize,
        });
      });
    },
    deleteAllocation(options) {
      const self = this;
      return Api.deleteAllocation(this.projectId, this.jobId, options.id).then(
        function () {
          let notification = {
            type: "success",
            showMessage: true,
            message: "Task Removed Successfully",
          };
          self.setNotification(notification);
          return self.getTempData({
            page: self.pageNumber,
            itemsPerPage: self.pageSize,
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.content {
  margin-left: 70px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.header h1 {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
.label {
  align-self: center;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}
.fileDetailValue,
.mappingDetail {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #25213b;
  text-overflow: unset;
}
label.fileDetailValue {
  align-self: center;
}
.fileImportForm {
  width: 60%;
  margin: 0 auto;
}
.mappingField .v-select__selection--comma {
  overflow: visible;
}
.mappingField,
.fileDetailField {
  width: 95%;
  height: 75px;
}
.mappingField .mappingFieldLabel,
.fileDetailField .fileDetailLabel {
  margin-top: 10px;
  align-self: baseline;
}
.supportAssignTask {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  padding: 24px 24px 16px;
  width: 100%;
}
.start_import_wrapper h6 {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}
.notification {
  position: absolute;
}
.loader-active {
  animation: mymove 3s infinite;
}
.disabled_assign_tasks_button {
  opacity: 0.5;
  pointer-events: none;
}
@keyframes mymove {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

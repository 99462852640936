module.exports = Object.freeze({
  PROJECT_TYPE: {
    CONTACT_BUILD_NAMED_ACCOUNT: "Contact Build (Named Account)",
  },
  ACCOUNT_TAB_DROPDOWN_OPTIONS: {
    OVERALL: "Overall",
    MATCHED_WITH_MASTER: "Matched With Master",
    MANUAL: "Manual",
  },
});
